export const randomSort = () => {
  return 0.5 - Math.random();
};

export const cards = [
  { id: 1, src: require("../../../../../../../assets/newyear2025/games/pairs/birth_certificate.png").default },
  { id: 2, src: require("../../../../../../../assets/newyear2025/games/pairs/business_card.png").default },
  { id: 3, src: require("../../../../../../../assets/newyear2025/games/pairs/driver_license.png").default },
  { id: 4, src: require("../../../../../../../assets/newyear2025/games/pairs/inn.png").default },
  { id: 5, src: require("../../../../../../../assets/newyear2025/games/pairs/international_passport.png").default },
  { id: 6, src: require("../../../../../../../assets/newyear2025/games/pairs/large_families.png").default },
  { id: 7, src: require("../../../../../../../assets/newyear2025/games/pairs/library_card.png").default },
  { id: 8, src: require("../../../../../../../assets/newyear2025/games/pairs/million_prizes.png").default },
  { id: 9, src: require("../../../../../../../assets/newyear2025/games/pairs/muscovite.png").default },
  { id: 10, src: require("../../../../../../../assets/newyear2025/games/pairs/passport.png").default },
  { id: 11, src: require("../../../../../../../assets/newyear2025/games/pairs/pet_info.png").default },
  { id: 12, src: require("../../../../../../../assets/newyear2025/games/pairs/policy.png").default },
  { id: 13, src: require("../../../../../../../assets/newyear2025/games/pairs/snils.png").default },
  { id: 14, src: require("../../../../../../../assets/newyear2025/games/pairs/three_card.png").default },
];

export const createGrid = () => {
  const gameCards = cards.sort(randomSort).slice(0, 8);
  const randomCards = [...gameCards, ...gameCards].sort(randomSort);

  return randomCards;
};
