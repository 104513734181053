import {
  mazeEntrance,
  mazeExit,
  mazeSize,
} from "../../components/pages/NewYear2025/components/Games/Maze/maze.constants";
import { mazeGenerateGrid } from "../../components/pages/NewYear2025/components/Games/Maze/maze.helpers";
import { ENY2025GameState, TNY2025State } from "../../types/NY2025.interface";
import { composeReducers } from "../../utils/redux";
import {
  NY2025_GAME_START,
  NY2025_GAME_RULES,
  NY2025_GAME_WIN,
  NY2025_GAME_LOSS,
  NY2025_GAME_END,
  NY2025_MAZE_MOVE,
  NY2025_MAZE_SET_TIME_REMAINING,
  NY2025_SET_IS_SILENT_MODE,
  NY2025_SET_SIZE_DATA,
  NY2025_STEEPLECHASE_UPDATE,
  NY2025_GIFT_CARDS_SET_PROJECT,
  NY2025_GIFT_CARDS_SET_ENTOURAGE,
  NY2025_GIFT_CARDS_SET_CONGRATULATIONS,
  NY2025_GIFT_CARDS_SET_STEP,
  NY2025_ELECTRONIC_HOUSE_KEY,
  NY2025_ELECTRONIC_HOUSE_LEVEL,
  NY2025_ELECTRONIC_HOUSE_SET_TIME_REMAINING,
  NY2025_QUIZ_SET_TIME_REMAINING,
  NY2025_QUIZ_SET_CORRECT_ANSWER_COUNT,
  NY2025_SET_ITEM_SELECTED,
  NY2025_ADD_GAME_LOADED,
  NY2025_SET_COLLECTED_OBJECTS,
  NY2025_PAIR_SET_GRID,
  NY2025_PAIR_SET_FOUNDED_INDEXES,
  NY2025_PAIR_SET_TIME,
  NY2025_ELECTRONIC_HOUSE_GIFT_COUNT,
  NY2025_SNOWMAN_SET_WORD,
  NY2025_SNOWMAN_SET_ATTEMPTS,
  NY2025_SNOWMAN_SET_GUESSED_WORD,
  NY2025_SNOWMAN_SET_CORRECT_LETTERS,
  NY2025_SNOWMAN_SET_WRONG_LETTERS,
  NY2025_TREE_SET_TIME_REMAINING,
  NY2025_TREE_SET_COLLECTED,
  NY2025_PAIR_SET_SELECTED,
  NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_OPENED,
  NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_SHOULD_OPEN,
  NY2025_SET_DATA_LOADING,
  NY2025_ELECTRONIC_HOUSE_INITIAL_POSITION,
  NY2025_SET_SOUND_ENABLED,
  NY2025_SET_GAMES_WON,
  NY2025_MERGE_COLLECTED_OBJECTS,
} from "../actions/NY2025Actions";

import {
  INITIAL_GAME_SPEED,
  INITIAL_OBSTACLE_FREQUENCY,
} from "../../components/pages/NewYear2025/components/Games/Steeplechase/Steeplechase.constants";
import { ENY2025Projects, TSteeplechase } from "../../types/NY2025.interface";
import { maxFindPairsTime } from "../../components/pages/NewYear2025/components/Games/FindPairs/FindPairs.constants";
import { getRandomElement } from "../../components/pages/NewYear2025/components/Games/BuildSnowman/snowman.helpers";
import {
  GAME_WORDS,
  INIT_GUESSED_WORD,
  MAX_ATTEMPTS,
} from "../../components/pages/NewYear2025/components/Games/BuildSnowman/snowman.constans";
import { createGrid } from "../../components/pages/NewYear2025/components/Games/FindPairs/Game/Game.helper";

const getInitialGamesState = (prevState?: TNY2025State): TNY2025State["games"] => ({
  maze: {
    position: { x: 1, y: 7 },
    timeRemaining: 60000,
    grid: mazeGenerateGrid({
      width: mazeSize,
      height: mazeSize,
      entrance: mazeEntrance,
      exit: mazeExit,
    }),
  },
  steeplechase: {
    positionY: 0,
    timeRemaining: 120000,
    score: 0,
    highScore: prevState?.games.steeplechase?.highScore ?? 0,
    gameSpeed: INITIAL_GAME_SPEED,
    obstacleFrequency: INITIAL_OBSTACLE_FREQUENCY,
    isJumping: false,
    obstacles: [],
    gifts: [],
  } as TSteeplechase,
  giftCards: {
    congratulations: "ag1",
    entourage: "1",
    project: ENY2025Projects.AG,
    step: 1,
  },
  electronicHouse: {
    initialPosition: true,
    timeRemaining: 1000 * 60 * 2 - 1000,
    level: 1,
    key: false,
    giftCount: 0,
    firstLevelGift: [
      { id: 1, checked: false, x: 0, y: 0 },
      { id: 2, checked: false, x: 0, y: 0 },
      { id: 3, checked: false, x: 0, y: 0 },
      { id: 4, checked: false, x: 0, y: 0 },
      { id: 5, checked: false, x: 0, y: 0 },
      { id: 6, checked: false, x: 0, y: 0 },
      { id: 7, checked: false, x: 0, y: 0 },
      { id: 8, checked: false, x: 0, y: 0 },
      { id: 9, checked: false, x: 0, y: 0 },
      { id: 10, checked: false, x: 0, y: 0 },
    ],
    secondLevelGift: [
      { id: 1, checked: false, x: 0, y: 0 },
      { id: 2, checked: false, x: 0, y: 0 },
      { id: 3, checked: false, x: 0, y: 0 },
      { id: 4, checked: false, x: 0, y: 0 },
      { id: 5, checked: false, x: 0, y: 0 },
      { id: 6, checked: false, x: 0, y: 0 },
      { id: 7, checked: false, x: 0, y: 0 },
      { id: 8, checked: false, x: 0, y: 0 },
      { id: 9, checked: false, x: 0, y: 0 },
      { id: 10, checked: false, x: 0, y: 0 },
    ],
  },
  quiz: {
    timeRemaining: 1000 * 30,
    correctAnswerCount: 0,
  },
  findPairs: {
    foundedIndexes: [],
    grid: createGrid(),
    time: maxFindPairsTime,
    selected: [],
  },
  snowman: {
    word: getRandomElement(GAME_WORDS),
    attempts: MAX_ATTEMPTS,
    guessedWord: INIT_GUESSED_WORD,
    wrongLetters: [],
    correctLetters: [],
  },
  tree: {
    timeRemaining: 90000,
    collected: {},
  },
});

const initialState: TNY2025State = {
  isSoundEnabled: true,
  isDataLoading: false,
  isSilentMode: true,
  itemsSelected: {},
  gamesLoaded: [],
  activeGame: null,
  gameState: ENY2025GameState.STARTED,
  gameIsPaused: false,
  gamesWon: [],
  collectedObjects: [],
  collectedObjectsWinModalOpen: false,
  collectedObjectsWinModalShouldOpen: false,
  games: getInitialGamesState(),
  sizeData: {
    scale: 1,
    ratio: 1,
    widthRatio: 1,
    heightRatio: 1,
  },
};

function mainReducer(state = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_GAME_RULES:
      return {
        ...state,
        gameState: ENY2025GameState.RULES,
        activeGame: action.game || state.activeGame,
        gameIsPaused: action.game === state.activeGame || !action.game ? true : false,
      };

    case NY2025_GAME_START:
      return {
        ...state,
        gameState: ENY2025GameState.STARTED,
        activeGame: action.game ?? state.activeGame,
        games: state.gameIsPaused ? { ...state.games } : getInitialGamesState(state),
        gameIsPaused: false,
      };

    case NY2025_GAME_WIN:
      return {
        ...state,
        gameState: ENY2025GameState.WIN,
      };

    case NY2025_GAME_LOSS:
      return {
        ...state,
        gameState: ENY2025GameState.LOSS,
      };

    case NY2025_GAME_END:
      return {
        ...state,
        activeGame: null,
      };

    case NY2025_SET_IS_SILENT_MODE:
      return {
        ...state,
        isSilentMode: action.payload,
      };

    case NY2025_SET_DATA_LOADING:
      return { ...state, isDataLoading: action.payload };

    case NY2025_SET_SIZE_DATA:
      return {
        ...state,
        sizeData: { ...state.sizeData, ...action.payload },
      };

    case NY2025_SET_ITEM_SELECTED:
      return {
        ...state,
        itemsSelected: { ...state.itemsSelected, [action.slideId]: action.itemId },
      };

    case NY2025_ADD_GAME_LOADED:
      return {
        ...state,
        gamesLoaded: [...state.gamesLoaded, action.payload],
      };

    case NY2025_SET_GAMES_WON:
      return {
        ...state,
        gamesWon: action.payload,
      };

    case NY2025_SET_COLLECTED_OBJECTS:
      return {
        ...state,
        collectedObjects: action.payload,
      };

    case NY2025_MERGE_COLLECTED_OBJECTS:
      return {
        ...state,
        collectedObjects: Array.from(new Set([...state.collectedObjects, ...action.payload])),
      };

    case NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_OPENED:
      return {
        ...state,
        collectedObjectsWinModalOpen: action.payload,
        collectedObjectsWinModalShouldOpen: false,
      };

    case NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_SHOULD_OPEN:
      return {
        ...state,
        collectedObjectsWinModalShouldOpen: action.payload,
      };

    case NY2025_SET_SOUND_ENABLED:
      return { ...state, isSoundEnabled: action.payload };

    default:
      return state;
  }
}

function mazeReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_MAZE_MOVE:
      return {
        ...state,
        games: {
          ...state.games,
          maze: {
            ...state.games.maze,
            position: action.position,
          },
        },
      };
    case NY2025_MAZE_SET_TIME_REMAINING:
      return {
        ...state,
        games: {
          ...state.games,
          maze: {
            ...state.games.maze,
            timeRemaining: action.timeRemaining,
          },
        },
      };
    default:
      return state;
  }
}

function steeplechaseReducer(state = initialState, action) {
  switch (action.type) {
    case NY2025_STEEPLECHASE_UPDATE:
      return {
        ...state,
        games: {
          ...state.games,
          steeplechase: {
            ...state.games.steeplechase,
            ...action.payload,
          },
        },
      };
    case NY2025_GAME_END:
      return {
        ...state,
        games: {
          ...state.games,
          steeplechase: {
            ...getInitialGamesState().steeplechase,
          },
        },
      };
    default:
      return state;
  }
}

function giftCardsReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_GIFT_CARDS_SET_PROJECT:
      return {
        ...state,
        games: {
          ...state.games,
          giftCards: {
            ...state.games.giftCards,
            project: action.payload,
          },
        },
      };
    case NY2025_GIFT_CARDS_SET_ENTOURAGE:
      return {
        ...state,
        games: {
          ...state.games,
          giftCards: {
            ...state.games.giftCards,
            entourage: action.payload,
          },
        },
      };
    case NY2025_GIFT_CARDS_SET_CONGRATULATIONS:
      return {
        ...state,
        games: {
          ...state.games,
          giftCards: {
            ...state.games.giftCards,
            congratulations: action.payload,
          },
        },
      };
    case NY2025_GIFT_CARDS_SET_STEP:
      return {
        ...state,
        games: {
          ...state.games,
          giftCards: {
            ...state.games.giftCards,
            step: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

function electronicHouseReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_ELECTRONIC_HOUSE_GIFT_COUNT:
      return {
        ...state,
        games: {
          ...state.games,
          electronicHouse: {
            ...state.games.electronicHouse,
            giftCount: action.payload,
          },
        },
      };
    case NY2025_ELECTRONIC_HOUSE_INITIAL_POSITION:
      return {
        ...state,
        games: {
          ...state.games,
          electronicHouse: {
            ...state.games.electronicHouse,
            initialPosition: false,
          },
        },
      };
    case NY2025_ELECTRONIC_HOUSE_KEY:
      return {
        ...state,
        games: {
          ...state.games,
          electronicHouse: {
            ...state.games.electronicHouse,
            key: action.payload,
          },
        },
      };
    case NY2025_ELECTRONIC_HOUSE_LEVEL:
      return {
        ...state,
        games: {
          ...state.games,
          electronicHouse: {
            ...state.games.electronicHouse,
            level: action.payload,
          },
        },
      };
    case NY2025_ELECTRONIC_HOUSE_SET_TIME_REMAINING:
      return {
        ...state,
        games: {
          ...state.games,
          electronicHouse: {
            ...state.games.electronicHouse,
            timeRemaining: action.payload,
          },
        },
      };
    case NY2025_GAME_WIN:
      return {
        ...state,
        games: {
          ...state.games,
          electronicHouse: {
            ...getInitialGamesState().electronicHouse,
          },
        },
      };
    default:
      return state;
  }
}

function quizReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_QUIZ_SET_TIME_REMAINING:
      return {
        ...state,
        games: {
          ...state.games,
          quiz: {
            ...state.games.quiz,
            timeRemaining: action.payload,
          },
        },
      };
    case NY2025_QUIZ_SET_CORRECT_ANSWER_COUNT:
      return {
        ...state,
        games: {
          ...state.games,
          quiz: {
            ...state.games.quiz,
            correctAnswerCount: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

function findPairReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_PAIR_SET_GRID:
      return {
        ...state,
        games: {
          ...state.games,
          findPairs: {
            ...state.games.findPairs,
            grid: action.payload,
          },
        },
      };
    case NY2025_PAIR_SET_FOUNDED_INDEXES:
      return {
        ...state,
        games: {
          ...state.games,
          findPairs: {
            ...state.games.findPairs,
            foundedIndexes: action.payload,
          },
        },
      };

    case NY2025_PAIR_SET_TIME:
      return {
        ...state,
        games: {
          ...state.games,
          findPairs: {
            ...state.games.findPairs,
            time: action.payload,
          },
        },
      };

    case NY2025_PAIR_SET_SELECTED:
      return {
        ...state,
        games: {
          ...state.games,
          findPairs: {
            ...state.games.findPairs,
            selected: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

function snowmanReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_SNOWMAN_SET_WORD:
      return {
        ...state,
        games: {
          ...state.games,
          snowman: {
            ...state.games.snowman,
            word: action.payload,
          },
        },
      };
    case NY2025_SNOWMAN_SET_ATTEMPTS:
      return {
        ...state,
        games: {
          ...state.games,
          snowman: {
            ...state.games.snowman,
            attempts: action.payload,
          },
        },
      };
    case NY2025_SNOWMAN_SET_GUESSED_WORD:
      return {
        ...state,
        games: {
          ...state.games,
          snowman: {
            ...state.games.snowman,
            guessedWord: action.payload,
          },
        },
      };
    case NY2025_SNOWMAN_SET_CORRECT_LETTERS:
      return {
        ...state,
        games: {
          ...state.games,
          snowman: {
            ...state.games.snowman,
            correctLetters: action.payload,
          },
        },
      };
    case NY2025_SNOWMAN_SET_WRONG_LETTERS:
      return {
        ...state,
        games: {
          ...state.games,
          snowman: {
            ...state.games.snowman,
            wrongLetters: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

function treeReducer(state: TNY2025State = initialState, action): TNY2025State {
  switch (action.type) {
    case NY2025_TREE_SET_TIME_REMAINING:
      return {
        ...state,
        games: {
          ...state.games,
          tree: {
            ...state.games.tree,
            timeRemaining: action.payload,
          },
        },
      };

    case NY2025_TREE_SET_COLLECTED:
      return {
        ...state,
        games: {
          ...state.games,
          tree: {
            ...state.games.tree,
            collected: { ...state.games.tree.collected, [action.payload]: true },
          },
        },
      };

    default:
      return state;
  }
}

export const ny2025Reducer = composeReducers(
  mainReducer,
  mazeReducer,
  steeplechaseReducer,
  giftCardsReducer,
  electronicHouseReducer,
  quizReducer,
  findPairReducer,
  snowmanReducer,
  treeReducer
);
