import "./Header.scss";
import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Logo } from "../../../../../assets/birthday10/icons/logo-white.svg";
import ProgressBar from "../ProgressBar/ProgressBar";
import Stats from "../Stats/Stats";
import Button from "../Button/Button";
import Select from "../../../../presentational/Controls/Select/Select";
import InputWithArrow from "../InputWithArrow/InputWithArrow";
import { ReactComponent as Burger } from "../../../../../assets/birthday10/icons/burger.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import InstructionModal from "../Modals/InstructionModal/InstructionModal";
import { NavLink } from "react-router-dom";
import { logout } from "../../../../../store/actions/UserActions";
import { useMediaQuery } from "react-responsive";
import { animationDuration } from "../Map/Map.constants";
import { MapInstance } from "../Map/Map.interface";
import { AuthAction, ComponentType, AuthActionType } from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import CrowdBirthday10Service, {
  IdeasGameResult,
  Sphere,
  ExpertGameResult,
} from "../../services/CrowdBirthday10.service";
import { isIOS } from "react-device-detect";
import { getGameData } from "../Games/ExpertGame/ExpertGame.utils";
import SupportForm from "../../../../containers/Forms/SupportForm/SupportForm";
import { verticalPinCorrection } from "../../CrowdBirthday10.constants";
import { useToasts } from "react-toast-notifications";
import { selectIsUserReady } from "../../../../../store/selectors/profile";

interface HeaderProps {
  mapInstance: MapInstance;
  startExpertGame: (id?) => void;
  onInit: (instance: HeaderIntance) => void;
  ideasGameResult?: IdeasGameResult;
  expertGameResult?: ExpertGameResult;
  completedSphereGroupCodes: any;
  onSkipInstruction: () => void;
  onSelectSphere?: (sphere: Sphere) => void;
}

export interface HeaderIntance {
  openInstructionModal: () => void;
  setExpertGameProgress: (v: number) => void;
}

const expertGameBlocks = getGameData();

const Header: React.FC<HeaderProps> = ({
  mapInstance,
  startExpertGame,
  onInit,
  ideasGameResult,
  expertGameResult,
  onSkipInstruction,
  onSelectSphere,
}) => {
  const [selectedSphere, setSelectedSphere] = useState<Sphere>(null);
  const [spheres, setSpheres] = useState<Sphere[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spheresAnswersCount, setSpheresAnswersCount] = useState(0);
  const [isSupportFormOpen, setIsSupportFormOpen] = useState(false); // State for SupportForm
  const user = useSelector((state: RootState) => state.user.userDetails);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const isUserReady = useSelector(selectIsUserReady);
  const { addToast } = useToasts();

  const isMobile = useMediaQuery({ query: `(max-width: ${570}px)` });

  useEffect(() => {
    const getSpheres = async () => {
      try {
        const res = await CrowdBirthday10Service.getSpheres();
        setSpheres(res);
      } catch (error) {
        console.error(error);
      }
    };
    getSpheres();
  }, []);

  useEffect(() => {
    onSelectSphere?.(selectedSphere);
  }, [selectedSphere]);

  useEffect(() => {
    if (user && mapInstance) {
      const init = () => {
        onInit({
          openInstructionModal: () => setIsModalOpen(true),
          setExpertGameProgress: (v) => setSpheresAnswersCount(v),
        });
      };
      const showLogoutToast = localStorage.getItem("showLogoutToast");
      if (isUserReady) {
        const actions = appContext.sudirService.getActions(ComponentType.BIRTHDAY10_HEADER);
        const redirectAction = actions.find((action) => action.type === AuthActionType.REDIRECT);
        if (redirectAction) {
          const { mapPosition } = redirectAction.args;
          if (mapPosition) {
            const { x, y, scale } = mapPosition;
            mapInstance.translate(x, y, scale);
          }
          setTimeout(() => {
            addToast("Вы успешно авторизовались", {
              id: "crowd-birthday-10-login",
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
              onDismiss: init,
            });
          }, 1500);
        } else {
          init();
        }
      } else if (showLogoutToast) {
        setTimeout(() => {
          addToast("Вы вышли из профиля", {
            id: "crowd-birthday-10-logout",
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
            onDismiss: init,
          });
        }, 1500);
        localStorage.removeItem("showLogoutToast");
      } else {
        init();
      }
    }
  }, [isUserReady, mapInstance, user]);

  const handleLogin = () => {
    const { x, y, scale } = mapInstance.getTranslate();
    const actions = [
      new AuthAction({
        component: ComponentType.BIRTHDAY10_HEADER,
        type: AuthActionType.REDIRECT,
        args: {
          returnUrl: window.location.href,
          mapPosition: { x, y, scale },
        },
      }),
    ];
    appContext.sudirService.authWithActions(actions);

    setIsModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.setItem("showLogoutToast", "true");
    dispatch(logout({ returnUrl: window.location.href }));
  };

  const handleSphereSelect = (sphere: Sphere) => {
    if (sphere) {
      setSelectedSphere(sphere);
      const block = expertGameBlocks.find((block) => block.id === sphere.groupCode);
      if (block && mapInstance) {
        let pinPosition = block.pinPos;

        if (expertGameResult) {
          const isCorrectAnswer = expertGameResult.answers.some(
            (answer) => answer.sphereId === sphere.id && answer.correct
          );

          if (isCorrectAnswer && block.pinCompletedPos) {
            pinPosition = block.pinCompletedPos;
          }
        }

        mapInstance.translateTo(
          pinPosition[0],
          pinPosition[1] - verticalPinCorrection,
          mapInstance.getTranslate().scale,
          isIOS ? 0 : animationDuration,
          true
        );
      }
    }
    setIsModalOpen(false);
  };

  const handleBurgerClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const openSupportForm = () => {
    setIsSupportFormOpen(true);
    setIsModalOpen(false);
  };

  return (
    <div className="birthday-header">
      <div className="birthday-header__left">
        <NavLink to="/propose" className="birthday-header__logo" target="_blank">
          <Logo className="birthday10-logo" />
        </NavLink>
        <div className="progress__container">
          <ProgressBar
            type="questions"
            numerator={user.loggedIn ? expertGameResult?.rightAnswerCount ?? 0 : 0}
            denominator={12}
            tooltipText="Ответьте правильно на все вопросы в игре «Почувствуй себя экспертом»"
          />
          <ProgressBar
            type="pin"
            numerator={user.loggedIn ? ideasGameResult?.rightAnswerCount ?? 0 : 0}
            denominator={10}
            tooltipText="Найдите все ненастоящие идеи на странице"
          />
        </div>
      </div>
      <div className="birthday-header__right">
        <Stats />
        {spheres && !isMobile && (
          <div onClick={() => setIsModalOpen(false)}>
            <Select
              value={selectedSphere?.groupName || null}
              placeholder={"Выберите сферу"}
              items={spheres}
              closeOnSelect={true}
              renderAsHtml
              bindTo="groupName"
              onItemSelect={handleSphereSelect}
              ComponentSelectInput={InputWithArrow}
            />
          </div>
        )}
        <Burger onClick={handleBurgerClick} className="birthday-header__burger" />
        <div className="birthday-header__divider"></div>
        {user?.loggedIn ? (
          <Button type="filled" text="Выйти" onClick={handleLogout} />
        ) : (
          <Button type="filled" text="Войти" onClick={handleLogin} />
        )}
      </div>
      {isModalOpen && (
        <InstructionModal
          onClose={handleCloseModal}
          isMain
          startExpertGame={startExpertGame}
          skipInstaction={onSkipInstruction}
          spheres={spheres}
          handleSphereSelect={handleSphereSelect}
          openSupportForm={openSupportForm}
          selectedSphere={selectedSphere}
        />
      )}
      {isSupportFormOpen && <SupportForm isOpened={isSupportFormOpen} close={() => setIsSupportFormOpen(false)} />}{" "}
      {/* Render SupportForm */}
    </div>
  );
};

export default Header;
