import { showMosruForm } from "../store/actions/LayoutActions";
import { store } from "../store/store";

export enum AuthActionType {
  AUTH,
  JOIN_PROJECT,
  REDIRECT,
  REDIRECT_AFTER_REG_FORM,
  JOIN_GAME,
  JOIN_PROJECTS_SILENTLY,
  OPEN_IDEAS_MODAL,
  OPEN_PROPOSE_MODAL,
  LIKE,
  CLICK_BIRD,
  START_GAME,
  OPEN_FORM,
  LOGIN,
  CLICK_INFORMER,
  VISIT_LAST_PAGE,
  REGISTER_ON_BROADCAST,
  REGISTER_ON_EVENT,
  THEMATIC_PROJECT_SELECT,
  GROUP_IDEA_FOUND,
  OPEN_QR_CODE_MODAL,
}

export enum ComponentType {
  MAIN,
  ABOUT_PAGE,
  BANNER,
  GENERIC_PROJECT_CONTAINER,
  BANNER_DIRECTIONS,
  NYGAME,
  HEADER,
  PROPOSE_IDEA_PAGE,
  PROPOSE_IDEA_PAGE_DETAILS,
  IDEA_LIST,
  IDEA_PAGE,
  NY_LANDING_PAGE,
  BIRTHDAY9_SPHERE_BLOCK,
  GAME,
  BIRTHDAY9_CONGRATS,
  BIRTHDAY9_GALLERY,
  PHOTOS_PAGE,
  LEISURE_LANDING_PAGE,
  LEISURE_HEADER,
  NY_2023_PAGE,
  NY_2025_PAGE,
  AFTER_REGISTRATION_FORM,
  DISCUSSION_LANDING,
  StreamsAndOnlineMeetings,
  Activities,
  DISCUSSION_LANDING_ALL_COMMENTS,
  DISCUSSION_LANDING_THEMATIC_PROJECTS,
  BIRTHDAY10,
  BIRTHDAY10_HEADER,
  BIRTHDAY10_OBJECT_GATHERING,
  BIRTHDAY10_EXPERT_GAME,
  CROSS_PROMO_POPUP,
  DISCUSSION_LANDING_EXPERTS,
}

export const mapPageToComponentType = {
  "/city": ComponentType.LEISURE_LANDING_PAGE,
};

export interface AuthActionParam {
  component: ComponentType;
  args?: any;
  type: AuthActionType;
}

export class SudirService {
  private _actions: AuthAction[] | any = [];

  constructor() {
    this.getActionsFromUrl();
  }

  getActions(component) {
    const componentActions = this._actions.filter((action) => action.component === component);
    this._actions = this._actions.filter((action) => action.component !== component);

    return componentActions;
  }

  set actions(value: AuthAction[]) {
    this._actions = value;
  }

  getActionsFromUrl() {
    const urlSearch = new URLSearchParams(window.location.search);
    const actions = urlSearch.get("actions");
    if (actions) {
      try {
        const decodedActions = decodeURIComponent(actions);
        const parsed = JSON.parse(decodedActions);
        this.actions = parsed.map((p) => new AuthAction(p));
      } catch (error) {
        console.error("Ошибка при разборе JSON:", error);
      } finally {
        this.clear();
      }
    }
  }

  authWithActions(actions: AuthAction[], returnUrl = window.location.href) {
    store.dispatch(showMosruForm(this.generateReturnUrlParameter(returnUrl, actions)));
  }

  generateReturnUrlParameter(returnUrl, actions?: AuthAction[]) {
    let _returnUrl = returnUrl;
    if (actions) {
      const data: AuthActionParam = {
        component: ComponentType.AFTER_REGISTRATION_FORM,
        type: AuthActionType.VISIT_LAST_PAGE,
        args: {
          lastVisitedPage: window.location.pathname,
        },
      };
      const redirectAfterRegFormAction = new AuthAction(data);
      const allActions = [...actions, redirectAfterRegFormAction];
      const stringifiedActions = JSON.stringify(allActions);
      const encodedActions = encodeURIComponent(stringifiedActions);
      _returnUrl += (returnUrl.includes("?") ? "&" : "?") + `actions=${encodedActions}`;
    }
    const encodedReturnUrl = encodeURIComponent(_returnUrl);
    return `returnUrl=${encodedReturnUrl}`;
  }

  generateActionsLink(returnLocation, actions?: AuthAction[]) {
    let link = returnLocation;
    if (actions) {
      const stringified = JSON.stringify(actions);
      const encoded = encodeURIComponent(stringified);
      link += `?&actions=${encoded}`;
    }
    return link;
  }

  clear() {
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete("actions");
    const resultString = urlObj.toString();
    window.history.pushState({}, "", resultString);
  }
}

export class AuthAction {
  component: ComponentType;
  args: any;
  type;

  constructor(action: AuthActionParam) {
    this.component = action.component;
    this.args = action.args;
    this.type = action.type;
  }
}
