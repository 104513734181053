import { useEffect, useMemo, useRef } from "react";

const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  const memoPrevValue = useMemo(() => ref.current, [value]);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return memoPrevValue;
};

export default usePrevious;
