import { useMediaQuery } from "react-responsive";
import {
  fhdWidth,
  hdWidth,
  mediumTabletWidth,
  phoneWidth,
  tabletWidth,
  wideTabletWidth,
} from "../utils/constants/widthConstants";

export function useMediaQueries() {
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = !isMobile && !isDesktop;

  return { isMobile, isDesktop, isTablet };
}

export function useIsMobile() {
  return useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
}

export function useIsDesktop() {
  return useMediaQuery({ query: `(min-width: ${tabletWidth + 1}px)` });
}

export function useIsTablet() {
  return useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
}

export function useIsWideTablet() {
  return useMediaQuery({ query: `(max-width: ${wideTabletWidth}px)` });
}

export function useIsMediumTablet() {
  return useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });
}

export function useIsFhd() {
  return useMediaQuery({ query: `(max-width: ${fhdWidth}px)` });
}

export function useIsHd() {
  return useMediaQuery({ query: `(max-width: ${hdWidth}px)` });
}
