export function getIndicesOfStr(searchStr: string, str: string, caseSensitive: boolean = false): number[] {
  let searchStrLen = searchStr.length;

  if (searchStrLen === 0) {
    return [];
  }

  let startIndex = 0;
  let index: number;
  const indices: number[] = [];

  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }

  return indices;
}

export function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}
