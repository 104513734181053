import { CongratulationVarians } from "../components/pages/NewYear2025/components/Games/GiftCards/GiftCards.types";

export const enum ENY2025GameState {
  RULES = "rules",
  STARTED = "started",
  LOSS = "loss",
  WIN = "win",
}

export const enum ENY2025Game {
  MAZE = "maze",
  GIFT_CARDS = "gift_cards",
  FIND_PAIR = "find_pair",
  COLLECT = "collect",
  QUIZ = "quiz",
  TREE = "tree",
  SNOWMAN = "snowman",
  STEEPLECHASE = "steeplechase",
  ELECTRONIC_HOUSE = "electronicHouse",
}

export const enum ENY2025Projects {
  GI = "gi",
  AG_KIDS = "ag_kids",
  AG = "ag",
  GZ = "gz",
  MY_ID = "my_id",
  NG = "ng",
  MP = "mp",
  ED = "ed",
}

export interface TMazePosition {
  x: number;
  y: number;
}

export type TMazeGrid = boolean[][];

export interface TNY2025State {
  isSoundEnabled: boolean;
  isDataLoading: boolean;
  sizeData: {
    scale: number;
    ratio: number;
    widthRatio: number;
    heightRatio: number;
  };
  isSilentMode: boolean;
  itemsSelected: {
    [slideId: string]: "main" | string | null;
  };
  collectedObjects: string[];
  collectedObjectsWinModalOpen: boolean;
  collectedObjectsWinModalShouldOpen: boolean;
  gamesLoaded: ENY2025Game[];
  activeGame: ENY2025Game | null;
  gameState: ENY2025GameState;
  gameIsPaused: boolean;
  gamesWon: string[];
  games: {
    steeplechase: TSteeplechase;
    maze: {
      position: TMazePosition;
      timeRemaining: number;
      grid: TMazeGrid;
    };
    giftCards: {
      project: ENY2025Projects;
      entourage: string;
      congratulations: CongratulationVarians;
      step: number;
    };
    electronicHouse: IElectronicHouse;
    quiz: IQuiz;
    findPairs: IFindPair;
    tree: ITree;
    snowman: ISnowman;
  };
}

export interface TSteeplechaseObstacle {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  type: string;
  layer: "lower" | "middle";
}

export interface TSteeplechaseGift {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  collected: boolean;
  type: "small" | "big";
  layer: "lower" | "middle";
}

export interface TSteeplechase {
  positionY: number;
  timeRemaining: number;
  obstacles: TSteeplechaseObstacle[];
  gifts: TSteeplechaseGift[];
  score: number;
  highScore: number;
  isJumping: boolean;
  gameSpeed: number;
  obstacleFrequency: number;
}

export interface IElectronicHouseGift {
  id: number;
  checked: boolean;
  x: number;
  y: number;
}

export interface IElectronicHouse {
  level: number;
  giftCount: number;
  key: boolean;
  timeRemaining: number;
  initialPosition: boolean;
  firstLevelGift: IElectronicHouseGift[];
  secondLevelGift: IElectronicHouseGift[];
}

export interface IQuiz {
  timeRemaining: number;
  correctAnswerCount: number;
}

export interface ITree {
  timeRemaining: number;
  collected: Record<string, boolean>;
}

export interface IFindPair {
  foundedIndexes: number[];
  grid: {
    id: number;
    src: any;
  }[];
  time: number;
  selected: number[];
}

export interface ISnowman {
  word: string;
  attempts: number;
  guessedWord: (string | null)[];
  correctLetters: string[];
  wrongLetters: string[];
}
