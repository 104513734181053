export const newYear2025Url = "/newyear2025";
export const newYear2025ProjectUrl = `${newYear2025Url}/:project/:slide?`;
export const newYear2025RulesUrl = "/new_year2025/rules.pdf";

export const baseWidth = 2180;
export const baseHeight = 1100;
export const maxRatioCoef = 4100 / 1600;
export const squareTabletRatio = 1.34;
export const fkRatio = 1.8;
export const fhdRatio = 1.98;
export const miniPhoneRatio = 1.59;
export const longPhoneRatio = 2.23;

export const enum ESide {
  LEFT = "left",
  RIGHT = "right",
  UP = "up",
  DOWN = "down",
}
