// @flow
import { fetchRequest, httpParamsSerialize } from "../../utils/axiosConfig";
import UserService from "../../services/userService";
import { SudirUserDetails } from "../../types/User.interface";
import { LocalStorageKeys, LocalStorageService } from "../../services/LocalStorage.service";
import { ApiStatusCode } from "../../types/Common.interface";

export const TEAM_LOGIN_HANDLE = "TEAM_LOGIN_HANDLE";
export const TEAM_LOGIN_SUCCESS = "TEAM_LOGIN_SUCCESS";
export const TEAM_LOGIN_FAIL = "TEAM_LOGIN_FAIL";

export const USER_SESSION_HANDLE = "USER_SESSION_HANDLE";
export const USER_SESSION_SUCCESS = "USER_SESSION_SUCCESS";
export const USER_SESSION_FAILED = "USER_SESSION_FAILED";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const UPDATE_PROFILE_AVATAR_REQUEST = "UPDATE_PROFILE_AVATAR_REQUEST";
export const UPDATE_PROFILE_AVATAR_SUCCESS = "UPDATE_PROFILE_AVATAR_SUCCESS";
export const UPDATE_PROFILE_AVATAR_FAIL = "UPDATE_PROFILE_AVATAR_FAIL";

export const UPLOAD_PROFILE_AVATAR_REQUEST = "UPLOAD_PROFILE_AVATAR_REQUEST";
export const UPLOAD_PROFILE_AVATAR_SUCCESS = "UPLOAD_PROFILE_AVATAR_SUCCESS";
export const UPLOAD_PROFILE_AVATAR_FAIL = "UPLOAD_PROFILE_AVATAR_FAIL";

export const GET_USER_PROJECT_STATE = "GET_USER_PROJECT_STATE";

export const SET_SUDIR_USER_DETAILS = "SET_SUDIR_USER_DETAILS";

export const LOGOUT = "LOGOUT";

export const teamLogin = (params, successCallback, failureCallback) => (dispatch) => {
  return UserService.login(params).then((response) => {
    if (
      response.status === ApiStatusCode.USER_NOT_FOUND ||
      response.status === ApiStatusCode.NOT_CORRECT_LOGIN_OR_PASSWORD
    ) {
      dispatch({
        type: TEAM_LOGIN_FAIL,
        payload: response.message,
      });
      failureCallback && failureCallback({ message: response.message });
      return;
    } else if (response.status === ApiStatusCode.USER_BLOCKED) {
      dispatch({
        type: TEAM_LOGIN_FAIL,
        payload: response.message,
      });
      return failureCallback({ isBlocked: true, blockExpiration: response.data?.blockExpiration });
    }

    UserService.getUser().then((userData) => {
      UserService.getUser();

      if (userData.data.id !== "_-1") {
        dispatch({
          type: TEAM_LOGIN_SUCCESS,
          payload: { loggedIn: true, ...userData.data },
        });

        if (typeof successCallback === "function") successCallback();
      }
    });
  });
};

export const getUser = (successCallback?: () => void, failCallback?: () => void) => (dispatch) => {
  dispatch({
    type: USER_SESSION_HANDLE,
  });

  return UserService.getUser().then((userData) => {
    if (userData.data.id === "_-1") {
      dispatch({
        type: USER_SESSION_SUCCESS,
        payload: { loggedIn: false, ...userData.data },
      });
      failCallback?.();
    } else {
      dispatch({
        type: USER_SESSION_SUCCESS,
        payload: { loggedIn: true, ...userData.data },
      });

      if (typeof successCallback === "function") successCallback();
    }
  });
};

export const setSudirUserDetails = (details: SudirUserDetails) => (dispatch) => {
  return dispatch({
    type: SET_SUDIR_USER_DETAILS,
    payload: { details },
  });
};

export const getUserProjectsState = (ids: string[]) => (dispatch) => {
  ids.map((id) => {
    UserService.getUserProjectsState(id).then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_USER_PROJECT_STATE,
          payload: { [id]: data },
        });
      }
    });
  });
};

export const logout = (queryParams?) => (dispatch) => {
  LocalStorageService.saveData(LocalStorageKeys.CROWD_FORCED_LOGOUT, "1");
  return UserService.logout(queryParams);
};

export const updateProfile = (params, onSuccess?) => (dispatch) => {
  return UserService.updateUser(params).then((response) => {
    if (response.status === ApiStatusCode.OK) {
      //ASK BACKEND TO RETURN UPDATED USERINFO
      dispatch(getUser());
      onSuccess && onSuccess();
    }
  });
};

export function changePassword(params) {
  return async function (dispatch) {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });

    const url = "/api/profile/changePassword";

    try {
      let response = await fetchRequest(url, params, "POST");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: CHANGE_PASSWORD_FAIL,
          error: true,
          payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        error: true,
        payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
      });
    }
  };
}

export function handleChangeAvatar(params) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_PROFILE_AVATAR_REQUEST,
    });

    const query = httpParamsSerialize(params);

    const url = `api/userpicture/setDefault?${query}`;

    try {
      let response = await fetchRequest(url, {}, "POST");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: UPDATE_PROFILE_AVATAR_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UPDATE_PROFILE_AVATAR_FAIL,
          error: true,
          payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_PROFILE_AVATAR_FAIL,
        error: true,
        payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
      });
    }
  };
}

export function handleUploadAvatar(params) {
  return async function (dispatch) {
    dispatch({
      type: UPLOAD_PROFILE_AVATAR_REQUEST,
    });

    const query = httpParamsSerialize(params.coords);
    const url = `api/userpicture/upload?${query}`;
    const data = Uint8Array.from(atob(params.src.match(/,(.*)$/)[1]), function (c) {
      return c.charCodeAt(0);
    });

    try {
      let response = await fetchRequest(url, data, "POST", {
        "Content-Type": params.type,
      });
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: UPLOAD_PROFILE_AVATAR_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UPLOAD_PROFILE_AVATAR_FAIL,
          error: true,
          payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
        });
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_PROFILE_AVATAR_FAIL,
        error: true,
        payload: new Error("Ошибка отправки данных. Пожалуйста, повторите попытку позже."),
      });
    }
  };
}
