export const GAME_TITLE = "ДАВАЙ СОБЕРЁМ СНЕГОВИКА!";

export const MAX_ATTEMPTS = 4;

export const WORD_LENGTH = 6;

export const INIT_GUESSED_WORD = new Array(WORD_LENGTH).fill(null);

export const GAME_WORDS = [
  "Январь",
  "Звезда",
  "Дождик",
  "Оливье",
  "Мишура",
  "Коньки",
  "Сугроб",
  "Снежок",
  "Метель",
  "Стишок",
  "Друзья",
  "Чудеса",
  "Сказка",
];

export const GAME_LETTERS = [
  ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И"],
  ["К", "Л", "М", "Н", "О", "П", "Р", "С", "Т"],
  ["У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ"],
  ["Ъ", "Ы", "Ь", "Э", "Ю", "Я"],
];