function getQuarter(date) {
  if (!date) return;
  const _date = typeof date === "string" ? new Date(date) : date;
  return Math.floor((_date.getMonth() + 3) / 3);
}

function getQuarterString(date) {
  if (!date) return;
  const _date = typeof date === "string" ? new Date(date) : date;
  const quarter = getQuarter(_date);
  const year = _date.getFullYear();
  return `${quarter} квартал ${year}`;
}

function relativeTime(date) {
  date = /T/.test(date) ? date : +date;
  let diff = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);
  // Секунды
  if (diff < 60) return "Только что";
  // Минуты
  diff = Math.round(diff / 60);
  if (diff < 60) {
    return (diff > 1 ? diff : "1") + " " + plural(diff, ["минуту", "минуты", "минут"]) + " назад";
  }
  // Часы
  diff = Math.round(diff / 60);
  if (diff < 24) {
    return (diff > 1 ? diff : "1") + " " + plural(diff, ["час", "часа", "часов"]) + " назад";
  }
  // Дни
  diff = Math.round(diff / 24);
  return (diff > 1 ? diff : "1") + " " + plural(diff, ["день", "дня", "дней"]) + " назад";
}

export function plural(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

// для формата DD.MM.YYYY
export const maskDate = (value: string) => {
  const checkSymbol = (s: string) => !!s && !isNaN(parseInt(s));

  const dd = value.slice(0, 2);
  const mm = checkSymbol(value.charAt(2)) ? value.slice(2, 4) : value.slice(3, 5);
  const yyyy = checkSymbol(value.charAt(5)) ? value.slice(5, 9) : value.slice(6, 10);

  const arr = `${dd}${mm}${yyyy}`.split("");
  let newValue = "";
  for (let i = 0; i < arr.length; i++) {
    if (!checkSymbol(arr[i])) {
      break;
    }
    if (i === 2 || i === 4) {
      newValue += ".";
    }
    newValue += arr[i];
  }

  return newValue;
};

export { getQuarter, getQuarterString, relativeTime };
