export const GAME_TITLE = "Бег с препятствиями";

export const GAME_WIDTH = 1750;
export const GAME_FIELD_HEIGHT = 387;

export const BOY_WIDTH = 210;
export const BOY_HEIGHT = 280;
export const BOY_START_X = 150;

export const BOY_HITBOX_WIDTH = 36;
export const BOY_HITBOX_HEIGHT = 104;
export const BOY_HITBOX_OFFSET_X = 90;
export const BOY_HITBOX_OFFSET_Y = 90;

export const INITIAL_GAME_SPEED = 2;
export const POINTS_PER_MS = 300 / 120000;

export const TARGET_SCORE = 150;

export const GRAVITY = 0.6;

export const INITIAL_OBSTACLE_FREQUENCY = 0.005;

export const OBSTACLE_WIDTH = 70;
export const OBSTACLE_HEIGHT = 70;

export const GIFT_SMALL_WIDTH = 50;
export const GIFT_SMALL_HEIGHT = 50;
export const GIFT_BIG_WIDTH = 90;
export const GIFT_BIG_HEIGHT = 90;

export const JUMP_SPEED = 15;

export const JUMP_BUFFER_TIME = 200;
export const COYOTE_TIME = 100;

export const MIN_GIFT_INTERVAL = 200;
export const MIN_DISTANCE_BETWEEN_OBJECTS = 200;

export const MS_PER_FRAME = 1000 / 60;
