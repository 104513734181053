import { AppColorsEnum } from "../../components/App.interface";
import ProjectService from "../../services/projectService";
import { RootState } from "../../types/State.interface";
import { debounce } from "../../utils";
import { footerTextColorsMap } from "../../utils/appColors";
import { selectIsUserChild } from "../selectors/profile";

export const SHOW_QRCODE_MODAL = "SHOW_QRCODE_MODAL";
export const HIDE_QRCODE_MODAL = "HIDE_QRCODE_MODAL";
export const SHOW_INFOMODAL = "SHOW_INFOMODAL";
export const HIDE_INFOMODAL = "HIDE_INFOMODAL";
export const SHOW_MAINTENANCE_MODAL = "SHOW_MAINTENANCE_MODAL";
export const HIDE_MAINTENANCE_MODAL = "HIDE_MAINTENANCE_MODAL";
export const ON_DISMISS_MODAL = "ON_DISMISS_MODAL";
export const SHOW_MOSRU_AUTH_FORM = "SHOW_MOSRU_AUTH_FORM";
export const TOGGLE_DOCUMENT_BLOCKED = "TOGGLE_DOCUMENT_BLOCKED";
export const SET_HEADER_OPTIONS = "SET_HEADER_OPTIONS";
export const WIDEN_PAGE = "WIDEN_PAGE";
export const LESSEN_PAGE = "LESSEN_PAGE";
export const FULLSCREEN_PAGE = "FULLSCREEN_PAGE";
export const SET_APP_COLOR = "SET_APP_COLOR";
export const SET_FOOTER_EXTRA = "SET_FOOTER_EXTRA";
export const EVENTS_TRACK_SCROLL_UPDATE = "EVENTS_TRACK_SCROLL_UPDATE";
export const DISABLE_BODY_SCROLL = "DISABLE_BODY_SCROLL";
export const ENABLE_BODY_SCROLL = "ENABLE_BODY_SCROLL";
export const SET_WEB_VIEW_MODE = "SET_WEB_VIEW_MODE";
export const SET_AFTER_REGISTRATION_FORM_OPEN = "SET_AFTER_REGISTRATION_FORM_OPEN";
export const SET_RESEND_EMAIL_MODAL_OPEN = "SET_RESEND_EMAIL_MODAL_OPEN";

const cache = new Map();

export const setAfterRegistrationFormOpen = (payload: boolean) => (dispatch) =>
  dispatch({ type: SET_AFTER_REGISTRATION_FORM_OPEN, payload });

export const setResendEmailModalOpen = (payload: boolean) => (dispatch) => {
  dispatch({ type: SET_RESEND_EMAIL_MODAL_OPEN, payload });
};

export const showQrCodeModal = () => (dispatch) => {
  return dispatch({
    type: SHOW_QRCODE_MODAL,
  });
};

export const hideQrCodeModal = () => (dispatch) => {
  return dispatch({
    type: HIDE_QRCODE_MODAL,
  });
};

export const showInfoModal = (message, jsx?, onDismiss?) => (dispatch) => {
  return dispatch({
    type: SHOW_INFOMODAL,
    payload: { message, jsx, onDismiss },
  });
};

export const hideInfoModal = () => (dispatch) => {
  return dispatch({
    type: HIDE_INFOMODAL,
  });
};

export const showMaintenanceModal = () => (dispatch) => {
  return dispatch({
    type: SHOW_MAINTENANCE_MODAL,
  });
};

export const hideMaintenanceModal = () => (dispatch) => {
  return dispatch({
    type: HIDE_MAINTENANCE_MODAL,
  });
};

export const setWebViewMode = (state) => (dispatch) => {
  return dispatch({
    type: SET_WEB_VIEW_MODE,
    payload: state,
  });
};

export const onDismissModal = () => (dispatch) => {
  return dispatch({
    type: ON_DISMISS_MODAL,
  });
};

export const showMosruForm = (returnUrl?) => (dispatch) => {
  return dispatch({
    type: SHOW_MOSRU_AUTH_FORM,
    payload: returnUrl,
  });
};

export const documentIsBlocked = (value) => (dispatch) => {
  return dispatch({
    type: TOGGLE_DOCUMENT_BLOCKED,
    payload: value,
  });
};

export const widenPage = () => (dispatch) => {
  return dispatch({
    type: WIDEN_PAGE,
  });
};

export const lessenPage = () => (dispatch) => {
  return dispatch({
    type: LESSEN_PAGE,
  });
};

export const fullScreenPage = () => (dispatch) => {
  return dispatch({
    type: FULLSCREEN_PAGE,
  });
};

export const disableBodyScroll = () => (dispatch) => {
  return dispatch({
    type: DISABLE_BODY_SCROLL,
  });
};

export const enableBodyScroll = () => (dispatch) => {
  return dispatch({
    type: ENABLE_BODY_SCROLL,
  });
};

export const setAppColor = (appColor: AppColorsEnum) => (dispatch) => {
  return dispatch({
    type: SET_APP_COLOR,
    payload: {
      appColor,
      footerTextColor: footerTextColorsMap[appColor],
    },
  });
};

export const setFooterExtra = (component) => (dispatch) => {
  return dispatch({
    type: SET_FOOTER_EXTRA,
    payload: {
      footerExtraComponent: component,
    },
  });
};

export interface SetHeaderOptions {
  logo?: {
    link?: string;
  };
  routes: any;
  type: HeaderRoutesType;
}

export const setHeaderOptions =
  ({ logo = { link: "" }, routes, type }: SetHeaderOptions) =>
  async (dispatch) => {
    let _routes = routes;

    if (type === HeaderRoutesType.MAIN) {
      const prms = ProjectService.getActiveIDs();
      cache.set(HeaderRoutesType.MAIN, prms);
      const { data } = await prms;
      cache.delete(HeaderRoutesType.MAIN);
      _routes = routes.create(data);
    }

    if (cache.size) {
      Promise.all(Array.from(cache.values())).then(() => {
        return dispatch({
          type: SET_HEADER_OPTIONS,
          payload: _routes,
        });
      });
    }

    return dispatch({
      type: SET_HEADER_OPTIONS,
      payload: {
        logo,
        routes: _routes,
        type,
      },
    });
  };

const eventsTrackScrollUpdateDebounce = debounce((dispatch) => {
  dispatch({
    type: EVENTS_TRACK_SCROLL_UPDATE,
  });
}, 1000);
export const eventsTrackScrollUpdate = () => eventsTrackScrollUpdateDebounce;

export enum HeaderRoutesType {
  MAIN = "MAIN",
  BIRTHDAY = "BIRTHDAY",
  DISCUSSION = "DISCUSSION",
}
