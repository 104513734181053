import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import BannersSection from "./BannersSection/BannersSection";
import MainPageLoader from "../../presentational/Loaders/MainPageLoader/MainPageLoader";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import SendIdeaForm from "../../containers/Forms/SendIdeaForm/SendIdeaForm";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import { getNewsLines } from "../../../store/actions/NewsLineActions";

import { lessenPage, setAppColor } from "../../../store/actions/LayoutActions";
import { getProjectBanners } from "../../../store/actions/ProjectActions";
import { getTotalStatistics } from "../../../store/actions/StatisticsActions";
import { getCategories } from "../../../store/actions/ProposeActions";
import { findUrlParam, clearUrlParams } from "../../../utils/index";
import { Idea } from "../../../types/Idea.interface";
import { RootState } from "../../../types/State.interface";
import SwiperCore from "swiper";

import { AppColorsEnum } from "../../App.interface";
import EventsTrackParentIdContext from "../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import IdeasSection from "./IdeasSection/IdeasSection";
import AboutSection from "./AboutSection/AboutSection";
import NewsSection from "./NewsSection/NewsSection";
import BannerLine from "./BannerLine/BannerLine";
import ResultSection from "./ResultSection/ResultSection";

import "./MainPage.scss";
import ExternalLinks from "./ExternalLinks/ExternalLinks";
import useRedirectUrl from "../../../hooks/useRedirectUrl";
import AgVideoWidget from "../../presentational/AgVideoWidget/AgVideoWidget";
import { selectEnvironment } from "../../../store/selectors/environment";
import { selectIsUserReady } from "../../../store/selectors/profile";

declare global {
  interface Window {
    ga: any;
  }
}

const MainPage = (props) => {
  useRedirectUrl();

  const dispatch = useDispatch();

  // SELCTORS
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isUserReady = useSelector(selectIsUserReady);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const banners = useSelector((state: RootState) => state.banners.banners);
  const categories = useSelector((state: RootState) => state.propose.categories);

  const currentProjects = useSelector((state: RootState) => state.projects.current);
  const announcementProjects = useSelector((state: RootState) => state.projects.announcement);
  const screenWidth = useSelector((state: RootState) => state.globalEvents.width);
  const totalStatistics = useSelector((state: RootState) => state.totalStatistics.stats);
  const ideas = useSelector((state: RootState) => state.ideas.ideas);
  const { isProduction } = useSelector(selectEnvironment);

  const linesState = useSelector((state: RootState) => state.lines);

  //STATE
  const [activeIdx, setActiveIdx] = useState(0);
  const [idea, setIdea] = useState<Idea | null>(null);
  const [ideaUploadModalOpen, setIdeaUploadModalOpen] = useState(false);
  const [queriedForm, setQueriedForm] = useState<"proposeForm" | "ideaUpload">();
  const [swiperLines, setSwiperLines] = useState<SwiperCore>();
  const [trio, setTrio] = useState<any>();

  // useLayoutEffect(() => {
  //   const key = LocalStorageKeys.NEW_YEAR_24_REDIRECTED;
  //   if (!LocalStorageService.getData(key)) {
  //     LocalStorageService.saveData(key, "1");
  //     window.location.href = "/newyear2024?utm_source=site&utm_medium=crowd&utm_campaign=ny24";
  //   }
  // }, []);

  useEffect(() => {
    dispatch(lessenPage());
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(getNewsLines());
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  // одновременный запуск перетяжки и трио слайдера для синхронного переключения
  useEffect(() => {
    if (swiperLines && trio) {
      const t = setTimeout(() => {
        if (linesState.lines?.length > 1) {
          swiperLines.autoplay.stop();
          swiperLines.autoplay.start();
        }

        trio.autoPlay();
      }, 100);

      return () => clearTimeout(t);
    }
  }, [linesState.lines?.length, swiperLines, trio]);

  // METHODS
  const loadData = () => {
    if (!banners) dispatch(getProjectBanners());
    if (!categories) dispatch(getCategories());
    if (!totalStatistics) dispatch(getTotalStatistics());
  };

  const canRender = () => {
    return banners && currentProjects && totalStatistics && categories && linesState.lines;
  };

  useEffect(() => {
    if (!ideas) return;
    const idea = ideas[getActualIndex(activeIdx)];
    setIdea(idea);
  }, [activeIdx]);

  useEffect(() => {
    setQueriedForm(
      findUrlParam("proposeForm", window.location.search)
        ? "proposeForm"
        : findUrlParam("ideaUpload", window.location.search)
        ? "ideaUpload"
        : undefined
    );
  }, []);

  useEffect(() => {
    // открытие форм после авторизации
    if (isUserReady && queriedForm && categories?.length) {
      switch (queriedForm) {
        case "proposeForm":
          const categoryCode = findUrlParam("category", window.location.search);
          let category;

          if (categoryCode) {
            category = categories?.find((el) => el.code === categoryCode);
          }

          // !proposeModalOpen && openProposeIdeaForm(null, category);
          break;
        case "ideaUpload":
          !ideaUploadModalOpen && setIdeaUploadModalOpen(true);
          break;
      }

      queriedForm && clearUrlParams();
    } else {
      setIdeaUploadModalOpen(false);
      //setProposeModalOpen(false);
    }
  }, [isUserReady, categories]);

  const getActualIndex = (index) => {
    if (!ideas) return 0;

    const itemsLength = ideas.length;
    return Math.sign(index) < 0 ? itemsLength - Math.abs(index) : index % itemsLength;
  };

  const setIdeaUploadModalOpenCb = useCallback(() => setIdeaUploadModalOpen(false), [ideaUploadModalOpen]);

  // RENDER

  const renderMeta = () => {
    const title = "Платформа Правительства Москвы «Город идей»";
    const description =
      "Платформа Правительства Москвы «Город идей» создана в 2014 году и является многофункциональной и современной платформой по взаимодействию органов исполнительной власти и жителей, сбору предложений по ключевым для столицы вопросам и подтверждению реализованных городом идей.";

    return <MetaDecorator title={title} description={description} opengraph={{ title, description }} />;
  };

  const renderExternalProjects = () => {
    return (
      <EventsTrackParentIdContext.Provider value="BLOCK_GKU_NTU">
        <EventsTrackWrapperScroll id={7}>
          <div className="mein__external">
            <ExternalLinks />
          </div>
        </EventsTrackWrapperScroll>
      </EventsTrackParentIdContext.Provider>
    );
  };

  const renderContent = () => {
    return (
      <FadeIn>
        <AgVideoWidget partnerId={isProduction ? 9 : 8} />
        <div className={`mein${linesState.lines?.length ? " new_line_top" : ""}`}>
          {renderMeta()}
          <BannerLine lines={linesState.lines} onInitSwiper={setSwiperLines} />
          {banners?.length && (
            <EventsTrackParentIdContext.Provider value="PAGE_MAIN">
              <BannersSection
                user={user}
                loggedIn={loggedIn}
                banners={banners}
                currentProjects={currentProjects}
                announcementProjects={announcementProjects}
                screenWidth={screenWidth}
                onInitTrio={setTrio}
              />
            </EventsTrackParentIdContext.Provider>
          )}

          <AboutSection />
          <NewsSection />
          <ResultSection />
          <IdeasSection />
          {renderExternalProjects()}
          {ideaUploadModalOpen && (
            <SendIdeaForm isOpened={ideaUploadModalOpen} user={user} onClose={() => setIdeaUploadModalOpenCb()} />
          )}
        </div>
      </FadeIn>
    );
  };

  const renderLoader = () => {
    return (
      <>
        {renderMeta()}

        <MainPageLoader />
      </>
    );
  };

  return <>{canRender() ? renderContent() : renderLoader()}</>;
};

export default MainPage;
