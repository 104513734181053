import { FC, useContext, useEffect, useState } from "react";
import SwiperCore from "swiper";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { SwiperSlide } from "swiper/react";
import { useHistory } from "react-router-dom";

import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import { RootState } from "../../../../../types/State.interface";
import { phoneWidth, tabletWidth } from "../../../../../utils/constants/widthConstants";
import activitiesData from "../../mocks/activities";
import ActivityCard from "./ActivityCard/ActivityCard";
import "./Activities.scss";
import DiscussionLandingService from "../../services/discussion-service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";
import { Activity } from "./Activity.interface";
import DiscussionEventRegistrationModal from "../DiscussionEventRegistrationModal/DiscussionEventRegistrationModal";
import { RegistrationStatus } from "../../DiscussionLanding.constants";
import { AppContext } from "../../../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import DiscussionEventRegistrationSuccessModal from "../DiscussionEventRegistrationModal/DiscussionEventRegistrationSuccessModal/DiscussionEventRegistrationSuccessModal";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { selectIsUserReady } from "../../../../../store/selectors/profile";

export interface ActivitiesProps {
  onLoadEnd?: (data) => void;
}

const Activities: FC<ActivitiesProps> = ({ onLoadEnd }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const appContext = useContext(AppContext);
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)` });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [activities, setActivities] = useState<Activity[]>(null);
  const [activitiesWithRegistartion, setActivitiesWithRegistartion] = useState<Activity[]>(null);
  const [selectedActivity, setSelectedActivity] = useState<Activity>(null);
  const [registrationStatusMap, setRegistrationStatusMap] = useState<{ [key: string]: boolean }>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const history = useHistory();
  const isUserReady = useSelector(selectIsUserReady);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!isUserReady || !activitiesWithRegistartion?.length) return;

    const actions = appContext.sudirService.getActions(ComponentType.Activities);
    if (actions.length) {
      const registerOnEventAction = actions.find((act) => act.type === AuthActionType.REGISTER_ON_EVENT);
      if (registerOnEventAction) {
        handleOnRegister(
          activitiesWithRegistartion.find((activity) => activity.id === registerOnEventAction.args.activityId)
        );
        history.push(`${window.location.pathname}?section=Activities`);
      }
    }
  }, [isUserReady, activitiesWithRegistartion]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser, discussionLandingContext.mediaPlatformId]);

  const fetchData = async () => {
    try {
      const response = await DiscussionLandingService.getEvents({
        mediaPlatformId: discussionLandingContext.mediaPlatformId,
      });
      if (response.status === ApiStatusCode.OK) {
        setActivities(response.data);
        if (response.data.length && currentUser.loggedIn) {
          await fetchEventsRegistrationList(response.data);
        }
      } else {
        displayError("Ошибка при получении мероприятий", response.message);
      }
    } catch (error) {
      displayError("Activities", "Ошибка при получении мероприятий");
    } finally {
      onLoadEnd([]);
    }
  };

  const fetchEventsRegistrationList = async (activities: Activity[]) => {
    try {
      const response = await DiscussionLandingService.getEventRegistrationList({
        eventIds: activities.map((s) => s.id).join(","),
      });
      if (response.status === ApiStatusCode.OK) {
        const registartionStatusMap = response.data.reduce((acc, item) => {
          acc[item.eventId] = item.registered;
          return acc;
        }, {});
        setRegistrationStatusMap(registartionStatusMap);
        setActivitiesWithRegistartion(
          activities.map((activity: Activity) => ({
            ...activity,
            status:
              registartionStatusMap[activity.id] && activity.status !== RegistrationStatus.FINISHED
                ? RegistrationStatus.REGISTERED
                : activity.status,
          }))
        );
      } else {
        displayError("Ошибка при получении списка зарег. юзеров", response.message);
      }
    } catch (error) {
      displayError("StreamsAndOnlineMeetings", "Ошибка при получении списка зарег. юзеров");
    }
  };

  const handleOnInit = () => {};

  const handleOnSlideChange = () => {};

  const isUserRegisteredOnEvent = (activity) => registrationStatusMap[activity.id];

  const handleOnRegister = (activity: Activity) => {
    if (!currentUser?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.Activities,
        type: AuthActionType.REGISTER_ON_EVENT,
        args: {
          activityId: activity.id,
        },
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    if (!isUserRegisteredOnEvent(activity)) {
      setSelectedActivity(activity);
    }
  };

  const handleSuccessRegistration = () => {
    setShowSuccessModal(true);
    fetchData();
  };

  if (!activitiesWithRegistartion?.length && !activities?.length) return <></>;

  return (
    <section className="discussion-landing-activities" id="Activities">
      <div className="discussion-landing-subtitle">Мероприятия</div>
      <div className="dls-cards">
        <CommonSlider
          onInit={handleOnInit}
          onSwiper={setSwiperInstance}
          onSlideChange={handleOnSlideChange}
          className="notifications__swiper"
          containterClassName="box-shadow-padding"
          options={{
            loop: true,
            spaceBetween: 24,
            slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            touchStartPreventDefault: false,
          }}
        >
          {(currentUser.loggedIn ? activitiesWithRegistartion : activities)?.map((item, index) => (
            <ActivityCard key={index} onRegister={handleOnRegister} data={item} />
          ))}
        </CommonSlider>
      </div>

      {selectedActivity && (
        <DiscussionEventRegistrationModal
          title={`Регистрация на мероприятие: «${selectedActivity.name}»`}
          id={selectedActivity.id}
          formKey={"eventId"}
          formMethod={DiscussionLandingService.registerOnEvent}
          onClose={() => setSelectedActivity(null)}
          onSuccess={handleSuccessRegistration}
        />
      )}

      {showSuccessModal && (
        <DiscussionEventRegistrationSuccessModal
          text="Ждем вас на мероприятии!"
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </section>
  );
};

export default Activities;
