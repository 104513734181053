import { FC, useContext, useEffect, useMemo, useState } from "react";
import "./DiscussionLandingPage.scss";
import AuthForm from "../../containers/Forms/AuthForm/AuthForm";
import Welcome from "./components/Welcome/Welcome";
import StreamsAndOnlineMeetings from "./components/StreamsAndOnlineMeetings/StreamsAndOnlineMeetings";
import Gallery from "./components/Gallery/Gallery";
import { useDispatch, useSelector } from "react-redux";
import { HeaderRoutesType, setAppColor, setHeaderOptions } from "../../../store/actions/LayoutActions";
import { routes as DiscussionRoutes } from "./routing/routes";
import ThematicProjects from "./components/ThematicProjects/ThematicProjects";
import MediaPlatform from "./components/MediaPlatform/MediaPlatform";
import Reviews from "./components/Reviews/Reviews";
import PhotoReport from "./components/PhotoReport/PhotoReport";
import Discussions from "./components/Discussions/Discussions";
import Rating from "./components/Rating/Rating";
import { routes as baseAppRoutes } from "../../App.helper";
import Activities from "./components/Activities/Activities";
import Experts from "./components/Experts/Experts";
import { ogBaseTitle, ogVideoTitle } from "./DiscussionLanding.constants";
import { RootState } from "../../../types/State.interface";
import { AuthActionType, ComponentType } from "../../../services/sudirService";
import { AppContext } from "../../Root";
import { useHistory, useLocation } from "react-router-dom";
import useConnectToProject from "./hooks/useConnectToProject";
import { AppColorsEnum } from "../../App.interface";
import OnlineTranslations, { translationIdQueryKey } from "./components/OnlineTranslations/OnlineTranslations";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import ogImage from "../../../assets/discussion-landing/repost.svg";
import ScreenLoader from "./components/ScreenLoader/ScreenLoader";
import classNames from "classnames";
import useDiscussionLandingContext from "./hooks/useDiscussionLandingContext";
import { selectIsUserReady } from "../../../store/selectors/profile";

enum DiscussionsLandingComponentKeys {
  ThematicProjects = "ThematicProjects",
  Stream = "Stream",
  MediaPlatform = "MediaPlatform",
  Activities = "Activities",
  Reviews = "Reviews",
  PhotoReport = "PhotoReport",
  Discussions = "Discussions",
  Rating = "Rating",
  Experts = "Experts",
}

const DiscussionLandingPage: FC = () => {
  const discussionLandingContext = useDiscussionLandingContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory<{ lastVisitedPage?: string }>();
  const appContext = useContext(AppContext);
  const connectToProject = useConnectToProject();

  const isUserReady = useSelector(selectIsUserReady);
  const currentProjects = useSelector((state: RootState) => state.projects.current);
  const headerConfig = useSelector((state: RootState) => state.layout.routes);
  const [sectionsReadyStateMap, setSectionsReadyStateMap] = useState({});
  const [progress, setProgress] = useState(0);

  const sectionName = useMemo(() => new URLSearchParams(location.search)?.get("section"), [location.search]);

  useEffect(() => {
    setProgress(Object.keys(sectionsReadyStateMap).length / Object.keys(DiscussionsLandingComponentKeys).length);
  }, [sectionsReadyStateMap]);

  useEffect(() => {
    if (Object.keys(sectionsReadyStateMap).length) {
      const watchedKeys = Object.keys(DiscussionsLandingComponentKeys);
      const allWatchedSectionsLoaded = watchedKeys.every((key) => key.toLocaleLowerCase() in sectionsReadyStateMap);
      if (allWatchedSectionsLoaded) {
        const filteredRoutes = DiscussionRoutes.baseRoutes.filter((route) => {
          const sectionKey = route.route.replace("?section=", "").toLowerCase();
          return sectionsReadyStateMap[sectionKey];
        });

        dispatch(
          setHeaderOptions({
            logo: {
              link: discussionLandingContext.url.slice(1),
            },
            routes: { baseRoutes: filteredRoutes, mobileRoutes: filteredRoutes },
            type: HeaderRoutesType.DISCUSSION,
          })
        );
      }
    }
  }, [sectionsReadyStateMap, discussionLandingContext.url]);

  useEffect(() => {
    return () => {
      if (headerConfig?.type === HeaderRoutesType.DISCUSSION) {
        dispatch(setHeaderOptions({ logo: null, routes: null, type: null }));
        dispatch(setHeaderOptions({ routes: baseAppRoutes, type: HeaderRoutesType.MAIN }));
      }
    };
  }, [headerConfig?.type]);

  useEffect(() => {
    if (headerConfig?.type === HeaderRoutesType.DISCUSSION) {
      if (sectionName) {
        const element = document.querySelector<HTMLElement>(`#${sectionName}`);
        const headerElementHeight = document.querySelector<HTMLElement>(".cr-header")?.clientHeight ?? 0;

        if (element) {
          window.scrollTo({ top: element.offsetTop - headerElementHeight, behavior: "smooth" });
        }
      }
    }
  }, [headerConfig?.type, sectionName]);

  useEffect(() => {
    dispatch(setHeaderOptions({ logo: null, routes: null, type: null }));
    dispatch(setAppColor(AppColorsEnum.WHITE));
    const wrapper: HTMLElement = document.querySelector(".wrapper");
    wrapper.classList.add("discussion-landing__wrapper");
    return () => {
      wrapper.classList.remove("discussion-landing__wrapper");
    };
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!isUserReady || !currentProjects) return;

    const actions = appContext.sudirService.getActions(ComponentType.DISCUSSION_LANDING);
    const joinProject = actions.find((act) => act.type === AuthActionType.JOIN_PROJECT);
    if (joinProject) {
      connectToProject();
    } else {
      const actions = appContext.sudirService.getActions(ComponentType.AFTER_REGISTRATION_FORM);
      const redirectToLastVisitedPage = actions.find((act) => act.type === AuthActionType.VISIT_LAST_PAGE);
      const lastVisitedPage = redirectToLastVisitedPage
        ? redirectToLastVisitedPage.args.lastVisitedPage
        : history.location.state?.lastVisitedPage;
      if (lastVisitedPage?.toLowerCase() === discussionLandingContext.url) {
        connectToProject(true);
      }
    }
  }, [isUserReady, currentProjects, discussionLandingContext.url]);

  const handleSectionDataLoadEnd = (data, key) => {
    setSectionsReadyStateMap((state) => ({ ...state, [key.toLowerCase()]: Boolean(data.length) }));
  };

  const isVideoOpen = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(translationIdQueryKey);
  }, [location.search]);

  return (
    <>
      <MetaDecorator
        title={"Платформа Правительства Москвы «Город идей»"}
        description={"Ты предлагаешь, мы воплощаем!"}
        opengraph={{
          title: isVideoOpen ? ogVideoTitle : ogBaseTitle,
          image: ogImage,
        }}
      />
      <AuthForm />
      {progress < 1 && <ScreenLoader progress={progress} />}
      <div className={classNames("discussion-landing")}>
        <Welcome />
        <div className="discussion-landing__container">
          <ThematicProjects
            onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.ThematicProjects)}
          />
          <Experts onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.Experts)} />
        </div>
        <div className="discussion-landing__container">
          <MediaPlatform
            onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.MediaPlatform)}
          />
          <StreamsAndOnlineMeetings
            onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.Stream)}
          />
          <Activities
            onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.Activities)}
          />
          <OnlineTranslations />
          <Reviews onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.Reviews)} />
          <PhotoReport
            onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.PhotoReport)}
          />
        </div>
        <Discussions
          onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.Discussions)}
        />
        <Rating onLoadEnd={(data) => handleSectionDataLoadEnd(data, DiscussionsLandingComponentKeys.Rating)} />
        <Gallery />
      </div>
    </>
  );
};

export default DiscussionLandingPage;
