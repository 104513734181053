import { TMazeGrid, TMazePosition } from "../../../../../../types/NY2025.interface";
import { ESide } from "../../../constants";
import { mazeEmptyCells } from "./maze.constants";

function shuffle(array: any[]): any[] {
  const newArray = [...array];

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
}

interface TMazeGenerateParams {
  width: number;
  height: number;
  entrance: TMazePosition;
  exit: TMazePosition;
}

interface TStackCell {
  x: number;
  y: number;
  dirs?: { x: number; y: number }[];
}

export function mazeGenerateGrid({ width, height, entrance, exit }: TMazeGenerateParams): TMazeGrid {
  // Инициализируем лабиринт стенами
  const maze: boolean[][] = [];
  for (let y = 0; y < height; y++) {
    const row: boolean[] = [];
    for (let x = 0; x < width; x++) {
      row.push(true);
    }
    maze.push(row);
  }

  // Очищаем первые 8x8 клеток
  const maxX1 = mazeEmptyCells;
  const maxY1 = mazeEmptyCells;
  for (let y = 0; y < maxY1; y++) {
    for (let x = 0; x < maxX1; x++) {
      maze[y][x] = false;
    }
  }

  // Очищаем последние 8x8 клеток
  const minX2 = width - 8;
  const minY2 = height - 8;
  for (let y = minY2; y < height; y++) {
    for (let x = minX2; x < width; x++) {
      maze[y][x] = false;
    }
  }

  maze[entrance.y][entrance.x] = false; // вход
  maze[exit.y][exit.x] = false; // выход

  const directions = [
    { x: 0, y: -1 }, // вверх
    { x: 1, y: 0 }, // вправо
    { x: 0, y: 1 }, // вниз
    { x: -1, y: 0 }, // влево
  ];

  // Стек для итеративного обхода
  const stack: TStackCell[] = [];

  stack.push({ ...entrance }); // вход

  while (stack.length > 0) {
    const current = stack[stack.length - 1];

    const x = current.x;
    const y = current.y;

    maze[y][x] = false; // отмечаем текущую клетку как пустую

    if (!current.dirs) {
      // Инициализируем и перемешиваем направления для текущей клетки
      current.dirs = shuffle(directions);
    }

    if (current.dirs.length === 0) {
      // Если все направления исчерпаны, возвращаемся назад
      stack.pop();
      continue;
    }

    const dir = current.dirs.pop()!;

    const nx = x + dir.x * 2;
    const ny = y + dir.y * 2;

    const isBorder = (x: number, y: number) => {
      // верх
      if (y === 0 && x > mazeEmptyCells - 1) return true;
      if (y === mazeEmptyCells && x < mazeEmptyCells + 1) return true;

      // лево
      if (x === 0 && y > mazeEmptyCells - 1) return true;
      if (x === mazeEmptyCells && y < mazeEmptyCells) return true;

      // низ
      if (y === height - 1 && x < width - mazeEmptyCells) return true;
      if (y === height - mazeEmptyCells - 1 && x > width - mazeEmptyCells - 1) return true;

      // право
      if (x === width - 1 && y < height - mazeEmptyCells) return true;
      if (x === width - mazeEmptyCells - 1 && y > height - mazeEmptyCells - 1) return true;

      return false;
    };

    if (!isBorder(nx, ny) && maze[ny] && maze[ny][nx]) {
      if (x === 8 && y < 8) {
        if (x !== entrance.x && y !== entrance.y) {
          continue;
        }
      }

      // Убираем стену между текущей и следующей клетками
      maze[y + dir.y][x + dir.x] = false;
      // Добавляем следующую клетку в стек для обработки
      stack.push({ x: nx, y: ny });
    }
  }

  return maze;
}

export const mazePrepareNewPosition = (prev: TMazePosition, side: ESide, maze: TMazeGrid, count: number = 1) => {
  let newPosition = { ...prev };

  for (let i = 0; i < count; i++) {
    const newPrev = { ...newPosition };

    switch (side) {
      case ESide.UP:
        newPosition.y -= 1;
        break;
      case ESide.DOWN:
        newPosition.y += 1;
        break;
      case ESide.LEFT:
        newPosition.x -= 1;
        break;
      case ESide.RIGHT:
        newPosition.x += 1;
        break;
      default:
        break;
    }

    if (newPosition.x < 0 || newPosition.x >= maze[0].length || newPosition.y < 0 || newPosition.y >= maze.length) {
      return newPrev;
    }

    if (maze[newPosition.y][newPosition.x]) {
      return newPrev;
    }
  }

  return newPosition;
};

export const mazeGetCharacterOffset = (characterPosition: TMazePosition, mazeCellSize: number) => {
  return { x: characterPosition.x * mazeCellSize, y: characterPosition.y * mazeCellSize };
};
