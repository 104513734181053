import { API } from "./api";
import { ENY2025Game, ENY2025GameState } from "../types/NY2025.interface";

const apiGameMap = {
  [ENY2025Game.MAZE]: "GI_LABYRINTH",
  [ENY2025Game.QUIZ]: "AG_QUIZ",
  [ENY2025Game.ELECTRONIC_HOUSE]: "ED_MARIO",
  [ENY2025Game.STEEPLECHASE]: "GZ_RUN",
  [ENY2025Game.TREE]: "NG_TREE",
  [ENY2025Game.FIND_PAIR]: "MYID_FINDCOUPLE",
  [ENY2025Game.SNOWMAN]: "KIDS_SNOWMAN",
  [ENY2025Game.GIFT_CARDS]: "MP_POSTCARD",
};

const apiGameStatusMap = {
  [ENY2025GameState.WIN]: "WIN",
  [ENY2025GameState.LOSS]: "LOST",
};

export const NY2025Service = {
  startGame: (game: ENY2025Game) => {
    return API.post(`/api/simple/game/start`, { gameType: apiGameMap[game] });
  },
  finishGame: (game: ENY2025Game, status: ENY2025GameState) => {
    return API.post(`/api/simple/game/finish`, { gameType: apiGameMap[game], status: apiGameStatusMap[status] });
  },
  getWonGamesCount: (): Promise<number> => {
    return API.get(`/api/simple/game/count`).then(({ data }) => data?.data);
  },
  getWonGames: (): Promise<string[]> => {
    return API.get(`/api/simple/game/getFinishedTypes`).then(({ data }) => data?.data || []);
  },
  saveCollectedObject: (id: string): Promise<string[]> => {
    return API.post(`/api/object/collection/game/save`, { id }).then(({ data }) => data?.data?.objectIds || []);
  },
  getCollectedObjects: (): Promise<string[]> => {
    return API.get(`/api/object/collection/game/getByCurrentUserId`).then(({ data }) => data?.data?.objectIds || []);
  },
};
