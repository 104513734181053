import React, { useContext, useEffect, useMemo, useState } from "react";
import Hammer from "react-hammerjs";
import { findRole, projectIsAnnouncement, projectIsCurrent, projectIsFinished, shuffle } from "../../../utils";

import "./BannerDirections.scss";
import {
  BannerCurrentStage,
  BannerDirectionsProps,
  BannerStage,
  BannerStageNull,
  BannerStages,
  BannerStageType,
  BannerStageTypes,
} from "./BannerDirections.interface";
import { useSelector } from "react-redux";
import "dayjs/locale/ru";
import { Stage, StageLinks } from "../../../types/Stage.interface";
import dayjs from "dayjs";
import { Banner } from "../../../types/Banner.interface";
import { RootState } from "../../../types/State.interface";
import { Project, ProjectStructure } from "../../../types/Projects.interface";
import { User } from "../../../types/User.interface";
import { AppContext } from "../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import GorodService, { GorodIdeaSphere } from "../../../services/gorodService";
import ProposeIdeaForm from "../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import ProposeIdeaSubmittedForm from "../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import EventsTrackWrapperScroll from "../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import CommonSlider from "../Sliders/CommonSlider/CommonSlider";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../utils/constants/widthConstants";
import SwiperCore from "swiper";
import Tooltip from "../Controls/Tooltip/Tooltip";
import { selectIsUserReady } from "../../../store/selectors/profile";

const BannerDirections = ({
  banner,
  currentProjects,
  announcementProjects,
  screenWidth,
  onDirectionsSwipe,
}: BannerDirectionsProps) => {
  const appContext = useContext(AppContext);

  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  const [anySwiper, setAnySwiper] = useState<SwiperCore>();
  const [swiperWidth, setSwiperWidth] = useState(0);
  const [isProposeModalOpen, setIsProposeModalOpen] = useState<boolean>(false);
  const [sphere, setSphere] = useState<GorodIdeaSphere>(null);
  const [stages, setStages] = useState<BannerStages>({});
  const [currentStage, setCurrentStage] = useState<BannerCurrentStage>({});
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] = useState<boolean>(false);
  const showProjectCategory = useSelector((state: RootState) => state.sittings.SHOW_PROJECT_CATEGORY);
  const spheres: GorodIdeaSphere[] = useSelector((state: RootState) => state.gorod.spheres);
  const projectsUserState = useSelector((state: RootState) => state.user.projectsState);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isUserReady = useSelector(selectIsUserReady);

  const shuffledSpheres = useMemo(() => {
    if (!spheres) {
      return [];
    }

    const pinnedIndexes: number[] = [];
    const unpinnedSpheres: GorodIdeaSphere[] = [];
    spheres.forEach((sphere, i) => {
      if (sphere.pinnedOnPromo) {
        pinnedIndexes.push(i);
      } else {
        unpinnedSpheres.push(sphere);
      }
    });

    const shuffled = shuffle(unpinnedSpheres.slice());
    for (const i of pinnedIndexes) {
      shuffled.splice(i, 0, spheres[i]);
    }

    return shuffled;
  }, [spheres]);

  let [currentMobileStage, setCurrentMobileStage] = useState<BannerCurrentStage>({});
  const userDetails: User = useSelector((state: any) => state.user.userDetails);

  useEffect(() => setStages(getStages()), [currentProjects, projectsUserState]);

  useEffect(() => {
    if (isCurrentProjectBanner(banner)) {
      if (!Object.keys(stages).length) return;

      const [cs, cms] = getCurrentStages(banner.projectId);
      setCurrentStage(cs);
      setCurrentMobileStage(cms);
    }
  }, [banner, stages]);

  // если строк в названии сферы > 2, то добавляем класс для обрезания текста
  // сразу для всех добавить нельзя, тк некоторые названия сфер содержат длинные слова и их нельзя обрезать
  useEffect(() => {
    document.querySelectorAll<HTMLSpanElement>(".banner-directions__slider-spheres__item__text").forEach((el) => {
      const { height } = el.getBoundingClientRect();
      const lines = height / parseInt(window.getComputedStyle(el, null).getPropertyValue("line-height"), 10);
      el.classList[lines > 2 ? "add" : "remove"]("banner-directions__slider-spheres__item__text_cut");
    });
  }, [anySwiper, spheres, isPhone, isTablet]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length || !isUserReady) return;

    const actions = appContext.sudirService.getActions(ComponentType.BANNER_DIRECTIONS);
    if (actions.length) {
      const redirectAction = actions.find((act) => act.type === AuthActionType.REDIRECT);
      if (redirectAction) {
        window.location.href = redirectAction.args.redirectLink;
      }

      const openProposeFormAction = actions.find((act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL);
      if (openProposeFormAction) {
        const { code } = openProposeFormAction.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        openProposeForm(sphere);
      }
    }
  }, [spheres, isUserReady]);

  const getStages = (): BannerStages => {
    const _stages: BannerStages = {};
    currentProjects?.forEach((project) => {
      _stages[project.id] = {};
      Object.values(BannerStageTypes).forEach((value) => {
        _stages[project.id][value] = createStage(getAllStages(project?.structure), value, project.id);
      });
    });
    return _stages;
  };

  const getAllStages = (structure: ProjectStructure): Stage[] => {
    let stages: Stage[] = [];

    if (structure?.stages.length) {
      stages = stages.concat(structure.stages);
    }

    if (structure.nodes.length) {
      structure.nodes.map((subStructure) => {
        stages = stages.concat(getAllStages(subStructure));
      });
    }

    return stages;
  };

  const getCurrentStages = (projectId): [currentStage: BannerCurrentStage, currentMobileStage: BannerCurrentStage] => {
    const _currentStage: BannerCurrentStage = { ...currentStage };
    Object.keys(stages[projectId]).forEach((key) => {
      _currentStage[key] = null;
      if (isAllStagesFinished(stages[projectId][key].filtered)) {
        _currentStage[key] = getStageFinishedText(stages[projectId][key], projectId);
      }
      if (isAllStagesAnnouncement(stages[projectId][key].filtered) || checkAnnouncementStages(stages[projectId][key])) {
        _currentStage[key] = getStageAnnouncementText(stages[projectId][key], projectId);
      }
      if (!_currentStage[key]) {
        _currentStage[key] = getCurrentStage(stages[projectId][key]);
      }
    });

    return [_currentStage, getCurrentMobileStage(stages[projectId], projectId)];
  };

  const getLinkToStage = (stage: Stage): string => {
    const platformUrl = banner.projectInfo.url;
    return stage && platformUrl && `${platformUrl}/${getLinkType(stage)}/${stage.id}`;
  };

  const isCurrentUserAssigned = (stageId, projectId): boolean => {
    return projectsUserState && projectsUserState[projectId]
      ? projectsUserState[projectId].stageIds?.includes(stageId) || projectsUserState[projectId].stageIds?.includes("*")
      : false;
  };

  const isShowBlock = (): boolean => {
    return Object.keys(currentStage).some((key) => Object.entries(currentStage[key]).length);
  };

  const stageIsString = (stage: any | string): boolean => typeof stage === "string";

  const getLinkType = (stage: Stage): string => {
    switch (stage.type) {
      case BannerStageTypes.GENERATION:
        return StageLinks.GENERATION;
      case BannerStageTypes.SIMPLE_DISCUSSION:
        return StageLinks.SIMPLE_DISCUSSION;
      case BannerStageTypes.VOTING:
        return StageLinks.VOTING;
      default:
        return "";
    }
  };

  const isLoggedIn = (): boolean => userDetails?.loggedIn;

  const stageValid = (stage: Stage, projectId: string): boolean => {
    return isLoggedIn() && findRole(userDetails, projectId)
      ? isCurrentUserAssigned(stage.id, projectId) &&
          !!stage.showOnPromo &&
          !userDetails.blockedOnProjects.includes(projectId)
      : !!stage.showOnPromo;
  };

  const sortStagesByValue = (stages: Stage[], value, sortType = "ASC"): Stage[] => {
    const _stages = [...stages];

    return _stages.sort((a, b) => {
      const _a = a[value];
      const _b = b[value];
      if (_a === _b) return 0;
      else if (_a === null) return 1;
      else if (_b === null) return -1;
      else if (_a > _b) return sortType === "ASC" ? 1 : -1;
      else return sortType === "ASC" ? -1 : 1;
    });
  };

  const getDateLastStage = (stages: Stage[], value: string, sortType?: string): string => {
    if (stages?.length) {
      const _stages: Stage[] = sortStagesByValue([...stages], value, sortType);
      return dayjs(_stages[0][value]).locale("ru").format("D MMMM");
    }
    return "";
  };

  const filterStagesByType = (stages: Stage[], type: BannerStageType): Stage[] => {
    return stages?.filter((stage) => stage.type === type);
  };

  const filterStagesByPriority = (stages: Stage[], projectId: string): Stage[] => {
    let resultArr: Stage[] = [];
    if (isSomeStageActive(stages)) {
      resultArr = stages?.length ? stages.filter((stage) => isStageActive(stage) && stageValid(stage, projectId)) : [];
    }
    if (!resultArr.length && isSomeStageAnnouncement(stages)) {
      resultArr = stages?.length
        ? stages.filter((stage) => isStageAnnouncement(stage) && stageValid(stage, projectId))
        : [];
    }

    if (!resultArr.length) {
      resultArr = stages?.length
        ? stages.filter((stage) => {
            return !isStageActive(stage) && !isStageAnnouncement(stage) && stageValid(stage, projectId);
          })
        : [];
    }
    return resultArr;
  };

  const hasStages = () => Boolean(Object.keys(stages).length);

  const isStageActive = (stage: Stage): boolean =>
    dayjs(stage.start).diff(dayjs(), "second") < 0 && !isStageFinished(stage);

  const isSomeStageActive = (stages: Stage[]): boolean => stages?.some((stage) => isStageActive(stage));

  const isStageFinished = (stage: Stage): boolean => dayjs(stage.finish).diff(dayjs(), "second") < 0;

  const isAllStagesFinished = (stages: Stage[]): boolean => {
    return stages?.length ? stages.every((stage) => isStageFinished(stage)) : false;
  };

  const isStageAnnouncement = (stage: Stage): boolean => dayjs(stage.start).diff(dayjs(), "second") > 0;

  const isAllStagesAnnouncement = (stages: Stage[]): boolean => {
    return stages?.length ? stages.every((stage) => isStageAnnouncement(stage)) : false;
  };

  const isSomeStageAnnouncement = (stages: Stage[]): boolean => {
    return !!stages?.length && stages.some((stage) => isStageAnnouncement(stage));
  };

  const getCurrentProjectById = (id: string): Project | undefined =>
    currentProjects?.find((project) => project.id === id);

  const checkAnnouncementStages = (stages: BannerStage): boolean => {
    return !!stages?.all?.length && isAllStagesAnnouncement(stages.all) && !stages?.filtered?.length;
  };

  const isCurrentProjectBanner = (banner: Banner): boolean => {
    return (
      projectIsCurrent(banner.projectInfo) && banner?.type === "PROJECT" && !!getCurrentProjectById(banner.projectId)
    );
  };

  const getCurrentIndex = (stagesArr: Stage[] | Stage[][] | any, currentIndex: number): number => {
    return stagesArr?.length && currentIndex < stagesArr.length - 1 ? ++currentIndex : 0;
  };

  const getCurrentStage = (stages: BannerStage): Stage | string => {
    const stage: Stage = { ...stages.filtered[stages.currentIndex] };
    stages.currentIndex = getCurrentIndex(stages.filtered, stages.currentIndex);
    return Object.keys(stage).length ? stage : getNullStageTitle(stages);
  };

  const createNestedArray = (array: Stage[], number): Stage[][] => {
    const newArray: Stage[][] = [];
    array.forEach((el, i) => {
      if (i % 2 === 0) {
        newArray.push(array.slice(i, i + number));
      }
    });
    return newArray;
  };

  const createMobileStage = (stages: BannerStage, projectId): Stage[][] | string => {
    if (isAllStagesFinished(stages.filtered)) {
      return getStageFinishedText(stages, projectId);
    }
    if (isAllStagesAnnouncement(stages.filtered) || checkAnnouncementStages(stages)) {
      return getStageAnnouncementText(stages, projectId);
    }

    const nestedArray: Stage[][] = createNestedArray([...stages.filtered], 2);
    return nestedArray.length ? nestedArray : [[]];
  };

  const getMobileStage = (stage: BannerStage, projectId): Stage[][] | string => {
    const mobileStage = createMobileStage(stage, projectId);
    return stageIsString(mobileStage) ? (mobileStage as string) : mobileStage;
  };

  const getCurrentMobileStage = (stages: BannerCurrentStage, projectId): BannerCurrentStage => {
    let currentMobileStages: BannerCurrentStage = {};
    Object.keys(stages).forEach((key) => {
      const _stage = getMobileStage(stages[key], projectId);
      if (stageIsString(_stage)) {
        currentMobileStages[key] = _stage as string;
      } else {
        (_stage as Stage[][]).forEach((stageArr) => {
          if (stageArr.length) {
            currentMobileStages = stageArr.reduce<BannerCurrentStage>(
              (prev, curr) => ({ ...prev, [curr.type]: curr }),
              currentMobileStages
            );
          } else {
            currentMobileStages[key] = getNullStageTitle(stages[key]);
          }
        });
      }
    });

    const sortedKeys = Object.keys(currentMobileStages).sort((a, b) =>
      stageIsString(currentMobileStages[b]) ? -1 : 1
    );
    return sortedKeys.reduce((prev, curr) => ({ ...prev, [curr]: currentMobileStages[curr] }), {});
  };

  const createStage = (stages: Stage[], type: BannerStageType, projectId: string): BannerStage => {
    const instance: BannerStage = {
      type: type,
      filtered: [],
      all: [],
      currentIndex: 0,
      mobileCurrentIndex: 0,
    };

    instance.all = filterStagesByType(stages, type);
    instance.filtered = filterStagesByPriority(instance.all, projectId);
    instance.filtered.sort((a, b) => {
      if (a.finish !== b.finish) {
        return +new Date(a.finish) < +new Date(b.finish) ? -1 : 1;
      }
      if (a.priority !== b.priority) {
        return a.priority !== null && a.priority < b.priority ? -1 : 1;
      }
      return +new Date(a.created) < +new Date(b.created) ? -1 : 1;
    });

    return instance;
  };

  const getStageFinishedText = (stage: BannerStage, projectId): string => {
    const stages = isLoggedIn()
      ? stage.filtered.filter((stage) => isCurrentUserAssigned(stage.id, projectId))
      : stage.filtered;
    const lastFinishDate = getDateLastStage(stages, "finish", "DESC");

    if (lastFinishDate) {
      switch (stage.type) {
        case BannerStageTypes.GENERATION:
          return `Предложение идей завершено ${lastFinishDate}`;
        case BannerStageTypes.SIMPLE_DISCUSSION:
          return `Обсуждения на проекте завершены ${lastFinishDate}`;
        case BannerStageTypes.VOTING:
          return `Оценка идей завершена ${lastFinishDate}`;
        default:
          return "";
      }
    }

    return getNullStageTitle(stage);
  };

  const getStageHeading = (stage: BannerStage, type?: BannerStageType) => {
    switch (stage?.type || type) {
      case BannerStageTypes.GENERATION:
        return "Предлагать";
      case BannerStageTypes.SIMPLE_DISCUSSION:
        return "Обсуждать";
      case BannerStageTypes.VOTING:
        return "Оценивать";
      default:
        return "";
    }
  };

  const getStageAnnouncementText = (stage: BannerStage, projectId): string => {
    const stages = isLoggedIn()
      ? stage.filtered.filter((stage) => isCurrentUserAssigned(stage.id, projectId))
      : stage.filtered;
    const lastStartDate = getDateLastStage(stages, "start");

    if (lastStartDate) {
      switch (stage.type) {
        case BannerStageTypes.GENERATION:
          return `Предлагайте свои идеи с ${lastStartDate}`;
        case BannerStageTypes.SIMPLE_DISCUSSION:
          return `Принимайте участие в обсуждении с ${lastStartDate}`;
        case BannerStageTypes.VOTING:
          return `Выбирайте лучшие идеи с ${lastStartDate}`;
        default:
          return "";
      }
    }

    return getNullStageTitle(stage);
  };

  const getNullStageTitle = (stages: BannerStage): string => {
    switch (stages.type) {
      case BannerStageTypes.GENERATION:
        return BannerStageNull.GENERATION;
      case BannerStageTypes.SIMPLE_DISCUSSION:
        return BannerStageNull.SIMPLE_DISCUSSION;
      case BannerStageTypes.VOTING:
        return BannerStageNull.VOTING;
      default:
        return "";
    }
  };

  const getStageTitle = (stage: Stage): string => stage.altName || stage.title || "";

  const getProjectCategoryDescription = (banner: Banner): string =>
    announcementProjects?.find((project) => banner.projectId && project.id === banner.projectId)?.structure
      ?.projectCategoryDescription || "";

  const goToPlatform = (ev, stage?) => {
    ev && ev.preventDefault();

    const link = stage ? getLinkToStage(stage) : banner.projectInfo.url;
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BANNER_DIRECTIONS,
        type: AuthActionType.REDIRECT,
        args: {
          redirectLink: link,
          absolutePath: true,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId: banner.projectId,
          absolutePath: true,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2]);
    }

    window.location.href = link;
  };

  const openProposeForm = (sphere: GorodIdeaSphere) => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BANNER_DIRECTIONS,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    setSphere(sphere);
    setIsProposeModalOpen(true);
  };

  // RENDER
  const renderMayorOrCommonBannerDirections = () => {
    const renderItem = (sphere, key) => {
      const core = (
        <EventsTrackWrapperClick needParent id={55} replace={[sphere.name]}>
          <div
            className={`banner-directions__slider-spheres__item ${
              !!sphere.logoAttachmentId ? "banner-directions__slider-spheres__item_with-bg" : ""
            }`}
            onClick={() => openProposeForm(sphere)}
            style={{
              backgroundImage: sphere.logoAttachmentId ? `url("/uploads/get/${sphere.logoAttachmentId}")` : undefined,
              color: sphere.titleColor,
              marginBottom: key < slidesPerColumn - 1 ? spaceBetween : undefined,
            }}
          >
            <span
              className="banner-directions__slider-spheres__item__text"
              dangerouslySetInnerHTML={{ __html: sphere.name }}
            />
          </div>
        </EventsTrackWrapperClick>
      );
      const withTooltip = sphere.name.replace("&nbsp;", " ").split("").length > 25;
      return withTooltip ? (
        <Tooltip text={sphere.name} idx={`${sphere.id}${key}`} key={key} place="top" isOuter>
          {core}
        </Tooltip>
      ) : (
        core
      );
    };

    const spaceBetween = isPhone ? 12 : 16;
    const itemWidth = isPhone ? 217 : isTablet ? 210 : 280;
    const slidesPerView = isPhone
      ? 1
      : Math.round(((swiperWidth + spaceBetween) / (itemWidth + spaceBetween)) * 1000) / 1000;
    const countItems = shuffledSpheres?.length;
    const slidesPerColumn = 2;
    const slides = [];
    for (let i = 0; i < countItems; i = i + slidesPerColumn) {
      const partSpheres = shuffledSpheres.slice(i, i + slidesPerColumn);
      slides.push(<div key={i}>{partSpheres.map((sphere, key) => renderItem(sphere, key))}</div>);
    }

    return (
      <div className="banner-directions__content">
        <div className="banner-directions__title">
          <p>Предлагайте идеи в любое время</p>{" "}
        </div>

        <CommonSlider
          className="banner-directions__slider-spheres"
          onResize={(swiper) => {
            setSwiperWidth(swiper.el.clientWidth);
          }}
          onSwiper={(swiper) => {
            setAnySwiper(swiper);
            setSwiperWidth(swiper.el.clientWidth);
            setTimeout(() => {
              !swiper.destroyed && swiper.slideToLoop(0, 0);
            }, 0);
          }}
          options={{
            slidesPerView: Math.round(slidesPerView * 1000) / 1000,
            slidesPerGroup: 1,
            spaceBetween,
          }}
        >
          {slides}
        </CommonSlider>
      </div>
    );
  };

  const renderAnnouncementProjectBannerDirections = () => {
    const projectCategoryDescription = getProjectCategoryDescription(banner);
    return (
      <div className="banner-directions__content">
        <div className="banner-directions__title">Подробнее о проекте</div>

        <EventsTrackWrapperClick needParent id={52} replace={[projectCategoryDescription]}>
          <div className="mein__directions-text">{projectCategoryDescription}</div>
        </EventsTrackWrapperClick>

        <EventsTrackWrapperClick needParent id={53}>
          <a className="banner-directions__link" href={`/announcement?id=${banner.projectId}`}>
            Узнать больше <span className="small-arrow small-arrow_white" />
          </a>
        </EventsTrackWrapperClick>
      </div>
    );
  };

  const renderCurrentProjectBannerDirection = () => {
    if (!hasStages()) return;

    const stages = screenWidth > 500 ? currentStage : currentMobileStage;
    const stagesKeys = stages ? Object.keys(stages) : [];

    const getClassNames = (stage, type?: BannerStageType): string => {
      let classes = `banner-directions__slider-actions__item banner-directions__slider-actions__item_${
        stage.type || type
      }`;
      if (stageIsString(stage) || (!stage.altName && !stage.title)) {
        classes += " banner-directions__slider-actions__item_disabled";
      }
      return classes;
    };

    const slidesPerView = isPhone ? 1 : 3;

    return (
      <div className="banner-directions__content">
        <div className="banner-directions__title">На проекте вы можете прямо сейчас</div>

        {stagesKeys.length > 0 && (
          <CommonSlider
            className={`banner-directions__slider-actions ${
              slidesPerView >= stagesKeys.length ? "banner-directions__slider-actions_no-arrows" : ""
            }`}
            onSwiper={setAnySwiper}
            options={{
              slidesPerView,
              slidesPerGroup: 1,
            }}
          >
            {stagesKeys.map((key) => {
              const heading = getStageHeading(stages[key], key as BannerStageType);
              return (
                <a
                  key={key}
                  className={getClassNames(stages[key], key as BannerStageType)}
                  onClick={(e) => goToPlatform(e, stages[key])}
                  href={stageIsString(stages[key]) ? "#" : getLinkToStage(stages[key])}
                >
                  {heading && <div className="banner-directions__slider-actions__item__title">{heading}</div>}
                  <div className="banner-directions__slider-actions__item__text">
                    {stageIsString(stages[key]) ? stages[key] : getStageTitle(stages[key])}
                  </div>
                </a>
              );
            })}
          </CommonSlider>
        )}

        {/* {!!banner.projectInfo.url && (
          <EventsTrackWrapperClick needParent id={54}>
            <a
              className="banner-directions__link"
              onClick={(e) => goToPlatform(e)}
              href={banner.projectInfo.url}
            >
              Все направления проекта{" "}
              <span className="small-arrow small-arrow_white" />
            </a>
          </EventsTrackWrapperClick>
        )} */}
      </div>
    );
  };
  const onCloseSubmittedPropose = () => {
    setIsSubmittedProposeModalOpen(false);
  };
  const handleOpenPropose = (sphere) => {
    setSphere(sphere);
    setIsProposeModalOpen(true);
  };
  return showProjectCategory ? (
    <React.Fragment>
      <Hammer
        onSwipe={(e) => {
          !anySwiper?.el?.contains(e.target) && onDirectionsSwipe(e);
        }}
      >
        <EventsTrackWrapperScroll id={3} needParent>
          <div className="banner-directions">
            {(banner?.type === "MAYOR" ||
              banner?.type === "COMMON" ||
              projectIsFinished(banner?.projectInfo) ||
              projectIsAnnouncement(banner?.projectInfo)) &&
              renderMayorOrCommonBannerDirections()}
            {projectIsCurrent(banner?.projectInfo) &&
              banner?.type === "PROJECT" &&
              isShowBlock() &&
              renderCurrentProjectBannerDirection()}{" "}
          </div>
        </EventsTrackWrapperScroll>
      </Hammer>
      {isProposeModalOpen && (
        <ProposeIdeaForm
          sphere={sphere}
          isOpen={isProposeModalOpen}
          onClose={() => setIsProposeModalOpen(false)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen={isSubmittedProposeModalOpen}
          onClose={onCloseSubmittedPropose}
          handleOpenPropose={handleOpenPropose}
        />
      )}
    </React.Fragment>
  ) : (
    <></>
  );
};

export default BannerDirections;
