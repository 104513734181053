import { Entries, Keys, ValueOf } from "../types/Common.interface";

export const entries = <T extends Record<string, any>>(obj: T): Entries<T> => Object.entries(obj) as any;

export const keys = <T extends Record<string, any>>(obj: T): Keys<T> => Object.keys(obj) as any;

export const values = <T extends Record<string, any>>(obj: T): ValueOf<T>[] => Object.values(obj) as any;

export const satisfiesRecord =
  <T>() =>
  <K extends PropertyKey>(rec: Record<K, T>) =>
    rec;
