import React, { FC, useEffect } from "react";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PhotoCard from "../../presentational/Cards/PhotoCard/PhotoCard";
import { clearUrlParams, findUrlParam } from "../../../utils/index";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import SendIdeaForm from "../../containers/Forms/SendIdeaForm/SendIdeaForm";
import "./PhotosPage.scss";
import { store } from "../../../store/store";
import { User } from "../../../types/User.interface";
import CommonSlider from "../../presentational/Sliders/CommonSlider/CommonSlider";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../utils/constants/widthConstants";
import Button from "../../presentational/Controls/Button/Button";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackWrapperScroll from "../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackParentIdContext from "../../containers/EventsTrack/EventsTrackParentIdContext";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

interface Props {
  loggedIn: boolean;
  isNeedAfterRegistrationForm: boolean;
  user?: User;
  photos: any;
  screenWidth: any;
  sudirService: any;
}

interface PhotoPageContentProps {
  photos: any;
  onUploadPhoto: (photo?: any, selector?: string) => void;
}

const scrollToList = (selector) => {
  document.querySelector(selector).scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

const PhotoPageContent: FC<PhotoPageContentProps> = ({ photos, onUploadPhoto }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const location = useLocation();

  const slidesPerView = isPhone ? 1 : isTablet ? 3 : 4;

  const photosLoaded = !!photos;
  useEffect(() => {
    if (location.hash && photosLoaded) {
      scrollToList(location.hash);
    }
  }, [location.hash, photosLoaded]);

  return (
    <>
      <EventsTrackParentIdContext.Provider value="BLOCK_INFOGRAPHIC">
        <div className="photos__instructions">
          <PageTitle text={"Основные условия"} />
          <PageDescription
            text={
              "Подтверждайте реализацию идей вместе с нами и получайте баллы! Перед загрузкой фотографии ознакомьтесь с основными условиями."
            }
          />

          <EventsTrackWrapperClick id={["MAIN_CONDITIONS", 182]} replace={["Загрузить фото"]}>
            <Button
              classList="upload-photo-button"
              type="filled"
              text="Загрузить фото"
              onClick={() => onUploadPhoto(null, ".upload-photo-button")}
            />
          </EventsTrackWrapperClick>

          <CommonSlider
            options={{
              slidesPerView: isPhone ? 1 : 3,
              slidesPerColumn: 1,
              slidesPerGroup: 1,
              spaceBetween: 24,
              allowTouchMove: isPhone,
            }}
            className="photos__instructions-list"
          >
            <div className="photos__instructions-list__item photos__instructions-list__item-photo">
              <div className="photos__instructions-list__item__step">Шаг 1</div>
              <div className="photos__instructions-list__item__title">Сделайте фото</div>
              <div className="photos__instructions-list__item__text">
                В разделе{" "}
                <EventsTrackWrapperClick needParent id={183} replace={["Галерея реализованных идей"]}>
                  <a href="/ideas" target="_blank">
                    «Галерея реализованных идей»
                  </a>
                </EventsTrackWrapperClick>{" "}
                выберите в форме загрузки идею из представленного списка и ознакомьтесь с всплывающей подсказкой.
                Сделайте фото выбранной реализованной идеи.
              </div>
            </div>
            <div className="photos__instructions-list__item photos__instructions-list__item-upload">
              <div className="photos__instructions-list__item__step">Шаг 2</div>
              <div className="photos__instructions-list__item__title">Загрузите фото</div>
              <div className="photos__instructions-list__item__text">
                При загрузке фото обратите внимание на требуемый формат и размер изображения. Ваше фото будет
                рассмотрено в течение 7 рабочих дней и, <strong>в случае соответствия</strong>{" "}
                <EventsTrackWrapperClick needParent id={183} replace={["Правила модерации"]}>
                  <a href="/moderation" target="_blank">
                    правилам модерации
                  </a>
                </EventsTrackWrapperClick>
                , опубликовано на сайте.
              </div>
            </div>
            <div className="photos__instructions-list__item photos__instructions-list__item-bonus">
              <div className="photos__instructions-list__item__step">Шаг 3</div>
              <div className="photos__instructions-list__item__title">Получите награду</div>
              <div className="photos__instructions-list__item__text">
                За опубликованную фотографию начисляются баллы, в соответствии с 
                <EventsTrackWrapperClick needParent id={183} replace={["Правила начисления баллов"]}>
                  <a href="/pointsRules" target="_blank">
                    правилами начисления баллов
                  </a>
                </EventsTrackWrapperClick>
                . Ознакомиться с подробной информацией и историей начисления баллов можно в личном кабинете на сайте{" "}
                <EventsTrackWrapperClick needParent id={183} replace={["Миллион призов"]}>
                  <a href="https://ag-vmeste.ru/profile/history" rel="noreferrer" target="_blank">
                    «Миллион призов»
                  </a>
                </EventsTrackWrapperClick>
                .
              </div>
            </div>
          </CommonSlider>
        </div>
      </EventsTrackParentIdContext.Provider>
      <EventsTrackParentIdContext.Provider value="BLOCK_COLLECT_PHOTOS">
        <EventsTrackWrapperScroll id="DOCSCROLL" needParent>
          <div className="photos__ideas" id="ideas">
            <div id="ideasAchor" style={{ position: "absolute", top: "-60px" }}></div>

            <div className="photos__ideas-title">Сбор фото ведется по следующим идеям</div>

            {photos && (
              <CommonSlider
                options={{
                  spaceBetween: 24,
                  slidesPerView,
                  slidesPerGroup: slidesPerView,
                }}
                className="photos__ideas-list"
                pagination
              >
                {photos.map((photo, idx) => (
                  <EventsTrackWrapperClick key={idx} id={251} needParent replace={[photo.title]}>
                    <div>
                      <PhotoCard onClick={() => onUploadPhoto(photo, ".photos__ideas-list")} photo={photo} />
                    </div>
                  </EventsTrackWrapperClick>
                ))}
              </CommonSlider>
            )}

            <div className="photos__ideas-text">
              Идеи для подтверждения обновляются в зависимости от сезонности и актуальности в определенный период
              времени. Сбор фото приостанавливается, когда получено необходимое количество фотографий. Уведомление об
              обновлении списка идей направляется в личный кабинет пользователя.
            </div>
          </div>
        </EventsTrackWrapperScroll>
      </EventsTrackParentIdContext.Provider>
    </>
  );
};

class PhotosPage extends React.Component<Props, any> {
  constructor(props) {
    super(props);

    this.state = {
      ideaModalOpen: false,
      selectedPhotoId: undefined,
      selectedProjectCategoryTagId: undefined,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
    const str1 = prevProps.photos?.map((p) => p.id).join("");
    const str2 = this.props.photos?.map((p) => p.id).join("");
    if (str1 !== str2) {
      if (document.location.hash === "#ideas") {
        setTimeout(() => {
          document.querySelector("#ideasAchor").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 1000);
      }
    }

    if (
      this.props.photos !== prevProps.photos ||
      this.props.loggedIn !== prevProps.loggedIn ||
      this.props.isNeedAfterRegistrationForm !== prevProps.isNeedAfterRegistrationForm
    ) {
      this.checkAuthOpenForm();
    }
  }

  componentDidMount() {
    // открытие форм после регистрации sudir
    const subscription = store.subscribe(() => {
      const formsAllowed = this.props.loggedIn && !this.props.isNeedAfterRegistrationForm;

      if (formsAllowed && findUrlParam("ideaUpload", window.location.search)) {
        !this.state.ideaModalOpen && this.setState({ ideaModalOpen: true });

        clearUrlParams();
        subscription();
      }
    });

    this.checkAuthOpenForm();

    // открытие форм после регистрации sudir
  }

  checkAuthOpenForm = () => {
    if (!this.props.photos?.length || !this.props.loggedIn || this.props.isNeedAfterRegistrationForm) {
      return;
    }

    const actions = this.props.sudirService.getActions(ComponentType.PHOTOS_PAGE);
    if (actions.length) {
      const openIdeaModal = actions.find((act) => act.type === AuthActionType.OPEN_IDEAS_MODAL);
      if (openIdeaModal) {
        const scrollSelector = openIdeaModal.args?.selector;
        scrollToList(scrollSelector);
        this.setState({
          ideaModalOpen: true,
          selectedPhotoId: openIdeaModal.args?.photoId,
          selectedProjectCategoryTagId: openIdeaModal.args?.projectCategoryTagId,
        });
      }
    }
  };

  uploadPhoto = (photo?: any, selector?: string) => {
    if (!this.props.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.PHOTOS_PAGE,
        type: AuthActionType.OPEN_IDEAS_MODAL,
        args: {
          photoId: photo?.id,
          projectCategoryTagId: photo?.projectCategoryTagId,
          selector: selector,
        },
      };

      const action1 = new AuthAction(thisContainerData);

      return this.props.sudirService.authWithActions([action1]);
    }

    this.setState({
      ideaModalOpen: true,
      selectedPhotoId: photo?.id,
      selectedProjectCategoryTagId: photo?.projectCategoryTagId,
    });
  };

  render() {
    const title = "«Город идей» — Подтверждайте реализацию идей";
    const description =
      "Подтверждайте реализацию идей вместе с нами! Вы можете отправить фотографии реализованных идей для публикации в разделе «Галерея реализованных идей».";
    return (
      <section className="photos">
        <MetaDecorator title={title} description={description} opengraph={{ title, description }} />

        <PhotoPageContent photos={this.props.photos} onUploadPhoto={this.uploadPhoto} />

        {this.state.ideaModalOpen && (
          <SendIdeaForm
            isOpened={this.state.ideaModalOpen}
            photoId={this.state.selectedPhotoId}
            projectCategoryTagId={this.state.selectedProjectCategoryTagId}
            onClose={() =>
              this.setState({
                ideaModalOpen: false,
                selectedPhotoId: undefined,
                selectedProjectCategoryTagId: undefined,
              })
            }
          />
        )}
      </section>
    );
  }
}

export default connect()(PhotosPage);
