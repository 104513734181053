import { selectIsNeedAfterRegistrationForm } from "../../store/selectors/profile";

const CompleteUserGuard = (state) => {
  return {
    passedGuard: state.user.loggedIn ? !selectIsNeedAfterRegistrationForm(state) : true,
    redirectIfFail: "/",
  };
};

export default CompleteUserGuard;
