import { EnvState } from "../../types/Env.interface";
import { ENV_LOADED } from "../actions/EnvironmentActions";

const initialState: EnvState = {
  captcha: "",
  googleClientId: "",
  googleTagsClientId: "",
  yandexClientId: "",
  isProduction: false,
};

export function envReducer(state: EnvState = initialState, action) {
  switch (action.type) {
    case ENV_LOADED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
