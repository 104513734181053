import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsUserChild, selectIsUserReady } from "../../../../store/selectors/profile";
import { AppModal } from "../../Modal/AppModal";
import { logout } from "../../../../store/actions/UserActions";
import Button from "../../../presentational/Controls/Button/Button";
import "./AuthChildForbidden.scss";

const AuthChildForbidden: FC = () => {
  const isUserChild = useSelector(selectIsUserChild);
  const isUserReady = useSelector(selectIsUserReady);
  const dispatch = useDispatch();
  const isModalOpen = isUserReady && isUserChild;
  const onClose = () => {
    dispatch(logout());
  };

  return (
    isModalOpen && (
      <AppModal
        center
        classNames={{
          modal: "auth-child-forbidden-modal",
          overlay: "auth-child-forbidden-overlay",
        }}
        overlayId="modal-overlay"
        closeIconId="close-modal-icon"
        blockScroll={false}
        closeIconSize={25}
        open
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={onClose}
      >
        <div className="auth-child-forbidden-modal__title">14+</div>

        <div className="auth-child-forbidden-modal__text">
          Данный раздел предназначен для пользователей старше 14 лет.
        </div>

        <div className="auth-child-forbidden-modal__buttons">
          <Button type="filled" text="Хорошо" onClick={onClose} />
        </div>
      </AppModal>
    )
  );
};

export default AuthChildForbidden;
