import { NY2025Service } from "../../services/NY2025Service";
import { TNY2025State } from "../../types/NY2025.interface";
import { RootState } from "../../types/State.interface";

export const NY2025_GAME_RULES = "NY2025_GAME_RULES";
export const NY2025_GAME_START = "NY2025_GAME_START";
export const NY2025_GAME_WIN = "NY2025_GAME_WIN";
export const NY2025_GAME_LOSS = "NY2025_GAME_LOSS";
export const NY2025_GAME_END = "NY2025_GAME_END";
export const NY2025_SET_IS_SILENT_MODE = "NY2025_SET_IS_SILENT_MODE";
export const NY2025_SET_SIZE_DATA = "NY2025_SET_SIZE_DATA";
export const NY2025_SET_ITEM_SELECTED = "NY2025_SET_ITEM_SELECTED";
export const NY2025_ADD_GAME_LOADED = "NY2025_ADD_GAME_LOADED";
export const NY2025_SET_GAMES_WON = "NY2025_SET_GAMES_WON";
export const NY2025_SET_COLLECTED_OBJECTS = "NY2025_SET_COLLECTED_OBJECTS";
export const NY2025_MERGE_COLLECTED_OBJECTS = "NY2025_MERGE_COLLECTED_OBJECTS";
export const NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_OPENED = "NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_OPENED";
export const NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_SHOULD_OPEN = "NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_SHOULD_OPEN";
export const NY2025_SET_DATA_LOADING = "NY2025_SET_DATA_LOADING";
export const NY2025_SET_SOUND_ENABLED = "NY2025_SET_SOUND_ENABLED";

export function ny2025GetGamesWon() {
  return (dispatch) => {
    NY2025Service.getWonGames().then((games) => {
      dispatch({
        type: NY2025_SET_GAMES_WON,
        payload: games,
      });
    });
  };
}

export function ny2025GetCollectedObjects() {
  return (dispatch) => {
    NY2025Service.getCollectedObjects().then((objects) => {
      dispatch({
        type: NY2025_SET_COLLECTED_OBJECTS,
        payload: objects,
      });
    });
  };
}

export function ny2025SaveCollectedObject(id: string) {
  return (dispatch, getState: () => RootState) => {
    const collectedObjects = getState().ny2025.collectedObjects;

    if (!collectedObjects.includes(id)) {
      dispatch({
        type: NY2025_MERGE_COLLECTED_OBJECTS,
        payload: [id],
      });

      NY2025Service.saveCollectedObject(id)
        .then((objects) => {
          dispatch({
            type: NY2025_MERGE_COLLECTED_OBJECTS,
            payload: objects,
          });

          if (objects.length === 40) {
            dispatch({
              type: NY2025_SET_COLLECTED_OBJECTS_WIN_MODAL_SHOULD_OPEN,
              payload: true,
            });
          }
        })
        .catch(() => {
          dispatch({
            type: NY2025_MERGE_COLLECTED_OBJECTS,
            payload: getState().ny2025.collectedObjects.filter((obj) => obj !== id),
          });
        });
    }
  };
}

export const NY2025_MAZE_MOVE = "NY2025_MAZE_MOVE";
export const NY2025_MAZE_SET_TIME_REMAINING = "NY2025_MAZE_SET_TIME_REMAINING";

export const NY2025_STEEPLECHASE_UPDATE = "NY2025_STEEPLECHASE_UPDATE";

export const NY2025_ELECTRONIC_HOUSE_GIFT_COUNT = "NY2025_ELECTRONIC_HOUSE_GIFT";
export const NY2025_ELECTRONIC_HOUSE_KEY = "NY2025_ELECTRONIC_HOUSE_KEY";
export const NY2025_ELECTRONIC_HOUSE_LEVEL = "NY2025_ELECTRONIC_HOUSE_LEVEL";
export const NY2025_ELECTRONIC_HOUSE_SET_TIME_REMAINING = "NY2025_ELECTRONIC_HOUSE_SET_TIME_REMAINING";
export const NY2025_ELECTRONIC_HOUSE_INITIAL_POSITION = "NY2025_ELECTRONIC_HOUSE_INITIAL_POSITION";

export const NY2025_QUIZ_SET_TIME_REMAINING = "NY2025_QUIZ_SET_TIME_REMAINING";
export const NY2025_QUIZ_SET_CORRECT_ANSWER_COUNT = "NY2025_QUIZ_SET_CORRECT_ANSWER_COUNT";

export const ny2025QuizTimeRemaining = (payload: TNY2025State["games"]["quiz"]["timeRemaining"]) => ({
  type: NY2025_QUIZ_SET_TIME_REMAINING,
  payload,
});

export const ny2025QuizCorrectAnswerCount = (payload: TNY2025State["games"]["quiz"]["correctAnswerCount"]) => ({
  type: NY2025_QUIZ_SET_CORRECT_ANSWER_COUNT,
  payload,
});

export const ny2025ElectronicHouseGiftCount = (payload: TNY2025State["games"]["electronicHouse"]["giftCount"]) => ({
  type: NY2025_ELECTRONIC_HOUSE_GIFT_COUNT,
  payload,
});

export const ny2025ElectronicHouseKey = (payload: TNY2025State["games"]["electronicHouse"]["key"]) => ({
  type: NY2025_ELECTRONIC_HOUSE_KEY,
  payload,
});

export const ny2025ElectronicHouseInitialPosition = (payload: TNY2025State["games"]["electronicHouse"]["key"]) => ({
  type: NY2025_ELECTRONIC_HOUSE_INITIAL_POSITION,
  payload,
});

export const ny2025ElectronicHouseLevel = (payload: TNY2025State["games"]["electronicHouse"]["level"]) => ({
  type: NY2025_ELECTRONIC_HOUSE_LEVEL,
  payload,
});

export const ny2025ElectronicHouseTimeRemaining = (
  payload: TNY2025State["games"]["electronicHouse"]["timeRemaining"]
) => ({
  type: NY2025_ELECTRONIC_HOUSE_SET_TIME_REMAINING,
  payload,
});

export const ny2025SteeplechaseUpdate = (payload) => ({
  type: NY2025_STEEPLECHASE_UPDATE,
  payload,
});

export const NY2025_GIFT_CARDS_SET_PROJECT = "NY2025_GIFT_CARDS_SET_PROJECT";
export const NY2025_GIFT_CARDS_SET_ENTOURAGE = "NY2025_GIFT_CARDS_SET_ENTOURAGE";
export const NY2025_GIFT_CARDS_SET_CONGRATULATIONS = "NY2025_GIFT_CARDS_SET_CONGRATULATIONS";
export const NY2025_GIFT_CARDS_SET_STEP = "NY2025_GIFT_CARDS_SET_STEP";

export const ny2025GiftCardsSetProject = (payload: TNY2025State["games"]["giftCards"]["project"]) => ({
  type: NY2025_GIFT_CARDS_SET_PROJECT,
  payload,
});

export const ny2025GiftCardsSetEntourage = (payload: TNY2025State["games"]["giftCards"]["entourage"]) => ({
  type: NY2025_GIFT_CARDS_SET_ENTOURAGE,
  payload,
});

export const ny2025GiftCardsSetCongratulations = (payload: TNY2025State["games"]["giftCards"]["congratulations"]) => ({
  type: NY2025_GIFT_CARDS_SET_CONGRATULATIONS,
  payload,
});

export const ny2025GiftCardsSetStep = (payload: TNY2025State["games"]["giftCards"]["step"]) => ({
  type: NY2025_GIFT_CARDS_SET_STEP,
  payload,
});

export const NY2025_PAIR_SET_GRID = "NY2025_PAIR_SET_GRID";
export const NY2025_PAIR_SET_FOUNDED_INDEXES = "NY2025_PAIR_SET_FOUNDED_INDEXES";
export const NY2025_PAIR_SET_TIME = "NY2025_PAIR_SET_TIME";
export const NY2025_PAIR_SET_SELECTED = "NY2025_PAIR_SET_SELECTED";

export const ny2025PairSetGrid = (payload: TNY2025State["games"]["findPairs"]["grid"]) => ({
  type: NY2025_PAIR_SET_GRID,
  payload,
});

export const ny2025PairSetFoundedIndexes = (payload: TNY2025State["games"]["findPairs"]["foundedIndexes"]) => ({
  type: NY2025_PAIR_SET_FOUNDED_INDEXES,
  payload,
});

export const ny2025PairSetTime = (payload: TNY2025State["games"]["findPairs"]["time"]) => ({
  type: NY2025_PAIR_SET_TIME,
  payload,
});

export const ny2025PairSetSelectedIndexes = (payload: TNY2025State["games"]["findPairs"]["selected"]) => ({
  type: NY2025_PAIR_SET_SELECTED,
  payload,
});

export const NY2025_TREE_SET_TIME_REMAINING = "NY2025_TREE_SET_TIME_REMAINING";
export const NY2025_TREE_SET_COLLECTED = "NY2025_TREE_SET_COLLECTED";

export const ny2025TreeSetTimeRemaining = (payload: TNY2025State["games"]["tree"]["timeRemaining"]) => ({
  type: NY2025_TREE_SET_TIME_REMAINING,
  payload,
});

export const ny2025TreeSetCollected = (payload: string) => ({
  type: NY2025_TREE_SET_COLLECTED,
  payload,
});

export const NY2025_SNOWMAN_SET_WORD = "NY2025_SNOWMAN_SET_WORD";
export const NY2025_SNOWMAN_SET_ATTEMPTS = "NY2025_SNOWMAN_SET_ATTEMPTS";
export const NY2025_SNOWMAN_SET_GUESSED_WORD = "NY2025_SNOWMAN_SET_GUESSED_WORD";
export const NY2025_SNOWMAN_SET_CORRECT_LETTERS = "NY2025_SNOWMAN_SET_CORRECT_LETTERS";
export const NY2025_SNOWMAN_SET_WRONG_LETTERS = "NY2025_SNOWMAN_SET_WRONG_LETTERS";

export const ny2025SnowmanSetWord = (payload: TNY2025State["games"]["snowman"]["word"]) => ({
  type: NY2025_SNOWMAN_SET_WORD,
  payload,
});

export const ny2025SnowmanSetAttempts = (payload: TNY2025State["games"]["snowman"]["attempts"]) => ({
  type: NY2025_SNOWMAN_SET_ATTEMPTS,
  payload,
});

export const ny2025SnowmanSetGuessedWord = (payload: TNY2025State["games"]["snowman"]["guessedWord"]) => ({
  type: NY2025_SNOWMAN_SET_GUESSED_WORD,
  payload,
});

export const ny2025SnowmanSetCorrectLetters = (payload: TNY2025State["games"]["snowman"]["correctLetters"]) => ({
  type: NY2025_SNOWMAN_SET_CORRECT_LETTERS,
  payload,
});

export const ny2025SnowmanSetWrongLetters = (payload: TNY2025State["games"]["snowman"]["wrongLetters"]) => ({
  type: NY2025_SNOWMAN_SET_WRONG_LETTERS,
  payload,
});

export const ny2025SetDataLoading = (payload: boolean) => ({
  type: NY2025_SET_DATA_LOADING,
  payload,
});

export const ny2025SetIsSoundEnabled = (payload: boolean) => ({
  type: NY2025_SET_SOUND_ENABLED,
  payload,
});
