import React, { Component } from "react";
import { connect } from "react-redux";

import FadeIn from "../../presentational/FadeIn/FadeIn";
import Photos from "../../pages/Photos/PhotosPage";
import Loader from "../../presentational/Loaders/Loader/Loader";
import SendIdeaForm from "../../containers/Forms/SendIdeaForm/SendIdeaForm";
import { loadPhotos } from "../../../store/actions/PhotosActions";
import { AppContext } from "../../Root";
import { selectIsNeedAfterRegistrationForm } from "../../../store/selectors/profile";

class PhotosContainer extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpened: false,
    };

    if (!this.props.photos) {
      this.props.loadPhotos();
    }
  }

  get isLoaded() {
    return this.props.photos;
  }

  render() {
    return (
      <>
        <FadeIn>
          <AppContext.Consumer>
            {({ sudirService }) => (
              <Photos
                isNeedAfterRegistrationForm={this.props.isNeedAfterRegistrationForm}
                sudirService={sudirService}
                loggedIn={this.props.loggedIn}
                photos={this.props.photos}
                screenWidth={this.props.screenWidth}
              />
            )}
          </AppContext.Consumer>
        </FadeIn>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    photos: state.photos.photos,
    loggedIn: state.user.loggedIn,
    isNeedAfterRegistrationForm: selectIsNeedAfterRegistrationForm(state),
    screenWidth: state.globalEvents.width,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPhotos: () => dispatch(loadPhotos()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotosContainer);
