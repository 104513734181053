export const replay = (audio: HTMLAudioElement) => {
  audio.pause();
  audio.currentTime = 0;

  audio.onended = () => {
    audio.load();
  };

  return audio.play().catch(() => {});
};
