import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button/Button";
import { useCallback } from "react";
import { RootState } from "../../../../types/State.interface";
import { userHasRoleInProject } from "../../../../utils";
import UserService from "../../../../services/userService";
import { hideInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import { getUser } from "../../../../store/actions/UserActions";
import { SUCCESS } from "../../../../types/statusCodes";
import { selectIsUserReady } from "../../../../store/selectors/profile";

const renderOkButton = (handler) => {
  return <Button type="outlined" text="Хорошо" onClick={handler} />;
};

const useConnectToProject = () => {
  const dispatch = useDispatch();

  const currentProjects = useSelector((state: RootState) => state.projects.current);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isUserReady = useSelector(selectIsUserReady);

  const connect = useCallback(
    (silently?: boolean) => {
      if (isUserReady && currentProjects?.length) {
        const [currentProject] = currentProjects;
        if (!userHasRoleInProject(user, currentProject.id)) {
          UserService.addToProjects({ ids: [currentProject.id] })
            .then(async (data) => {
              const handler = () => {
                dispatch(hideInfoModal());
                dispatch(getUser());
              };

              if (silently) {
                handler();
              } else {
                const msg =
                  data.status === SUCCESS
                    ? `Спасибо! Вы стали участником проекта «${currentProject.title || currentProject.nameOnPromo}».`
                    : data.message;

                dispatch(showInfoModal(msg, renderOkButton(handler), handler));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    [currentProjects, isUserReady]
  );

  return connect;
};

export default useConnectToProject;
