export interface Role {
  projectId: string;
  projectTeamMember: boolean;
  type: "PARTICIPANT" | any; // add more
}

export enum UserTypeEnum {
  FULL = "FULL",
  STANDARD = "STANDARD",
  SHORT = "SHORT",
}

export enum UserActionTypeEnum {
  REGISTRATION,
  JOIN_PROJECT,
  SEND_PHOTO,
}

export interface User {
  accountType: "SUDIR" | "NATIVE";
  agreementConfirmed: boolean;
  birthYear: number;
  birthDate: string;
  blocked: boolean;
  blockedOnProjects: any[];
  commentCount: number;
  controlAnswer: any;
  controlQuestion: any;
  email: string;
  emailVerified: boolean;
  firstName: string;
  gender: "MALE" | "FEMALE" | "UNKNOWN";
  id: string;
  ideaPhotoCount: number;
  lastName: string;
  loggedIn: boolean;
  login: null;
  main: boolean;
  moscowDistrict: string;
  moscowRegion: string;
  nativeRegistrationDate: any;
  partiallyFilled: boolean;
  phone: string;
  pictureId: string;
  projects: string[];
  projectsCount: number;
  proposalCount: number;
  proposalVoteCount: number;
  registrationDate: string;
  roles: Role[];
  showParticipation: boolean;
  ssoid: string;
  sudirRegistrationDate: any;
  userSystems: any[];
  passwordLastChanged: any;
  passwordExpired: boolean;
  points: number | null;
  userType: UserTypeEnum;
  deletedSudir: boolean;
  showName: boolean;
  agWidgetUser?: string;
  agWidgetSignature?: string;
  online?: boolean;
  expert?: boolean;
  chiefExpert?: boolean;
  frontman?: boolean;
  projectBoss?: boolean;
  facilitator?: boolean;
  projectFacilitator?: boolean;
  manager?: boolean;
  description?: string;
  position?: string;
  child: boolean;
}

export interface SudirUserDetails {
  sudirProfileExists: boolean;
  crowdProfileExists: boolean;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface ProfileState {
  userDetails: User | null;
  sudirUserDetails: SudirUserDetails | null;
  projectsState: { [index: string]: UserProjectState } | null;
  loggedIn: boolean;
  forcedLoggedOut: boolean;
  error: string;
  isFetching: boolean;
}

export interface PasswordChangeParams {
  currentPassword?: string;
  newPassword: string;
}

export interface UserProjectState {
  participant: boolean;
  questionnaireSent: boolean;
  stageIds: string[];
}

export interface UserDto {
  id: string;
  firstName: string;
  lastName: string;
  pictureId: string;
  blocked: boolean;
  showName: boolean;
  deletedSudir: boolean;
}

export enum UserRole {
  PROJECT_BOSS = "PROJECT_BOSS",
  MANAGER = "MANAGER",
  FACILITATOR = "FACILITATOR",
  CHIEF_EXPERT = "CHIEF_EXPERT",
  EXPERT = "EXPERT",
  ADMINISTRATOR = "ADMINISTRATOR",
  ANALYST = "ANALYST",
  SUPPORT = "SUPPORT",
  VIP = "VIP",
  PARTICIPANT = "PARTICIPANT",
  EX_PARTICIPANT = "EX_PARTICIPANT",
  POTENTIAL = "POTENTIAL",
  PROJECT_FACILITATOR = "PROJECT_FACILITATOR",
  FACILITATOR_IZRG = "FACILITATOR_IZRG",
}
